import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { Box, Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import * as actions from "../../api/actions/pos";
import { connect } from "react-redux";
import { AppContext } from "../../App";
import { controller, endpoints } from "../../api/actions/api";
import { ToFormData } from "../../hooks/Method";

const ProfitBreakdown = ({ _getByObj }) => {
  const { t } = useTranslation();
  const { selStore } = useContext(AppContext);

  const [values, setValues] = useState({
    sellItems: 0,
    sellPrice: 0,
    purchPrice: 0,
    expenses: 0,
  });

  useEffect(() => {
    selStore &&
      _getByObj(
        endpoints.Dashboard + controller.ProfitBreakdown,
        ToFormData({
          storeId: selStore,
          value: moment().format("MM/YYYY"),
        }),
        null,
        (res) => {
          res.status === 200 && setValues(res.result);
        }
      );

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  const options = {
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        offsetY: 10,
        donut: {
          labels: {
            show: true,
          },
        },
      },
    },
    grid: {
      padding: {
        bottom: -90,
      },
    },
    labels: [
      t("numberOfItemSold"),
      t("sellPrice"),
      t("purchasePrice"),
      t("totalExpense"),
    ],
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
    legend: {
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex];
      },
      show: true,
      position: "bottom",
      horizontalAlign: "bottom",
      fontSize: "16px",
      fontWeight: "600",
      itemMargin: {
        horizontal: 10,
        vertical: 8,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
        },
      },
    ],
  };

  const series = [
    values.sellItems,
    values.sellPrice,
    values.purchPrice,
    values.expenses,
  ];

  return (
    <Paper className="p-15">
      {/* Chart */}
      <Typography varint="h6" className="fs-18 fw-5 gray-color mb-10">
        {t("profitBreakdown")}
      </Typography>
      <Box>
        <ReactApexChart
          options={options}
          series={series}
          type="donut"
          height={330}
        />
      </Box>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.pos.authUser,
  _defStore: state.pos.defStore,
});

const mapActiontoProps = { _getByObj: actions.getByObj };

export default connect(mapStateToProps, mapActiontoProps)(ProfitBreakdown);
