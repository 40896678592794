import { ClearRounded, VisibilityRounded } from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Dialog, Table } from "../../layout";
import ViewData from "./ViewData";
import * as actions from "../../api/actions/pos";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "../../App";
import { controller, endpoints } from "../../api/actions/api";
import { useTranslation } from "react-i18next";
import { ChangeArrayKey } from "../../hooks/Method";
import { CSVLink } from "react-csv";

const StockTransfers = (props) => {
  const { _clear, _getById, _delete } = props;
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const navigate = useNavigate();
  const { setLoader, selStore, org } = useContext(AppContext);
  const [viewItems, setViewItems] = useState([]);
  const [csvHeader, setCsvHeader] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (selStore) {
      const onComplete = (res) => {
        setLoader(false);
        res.status === 200 &&
          setTblData(
            res.result.map((obj) => ({
              ...obj,
              from: obj.fromStore.storeName,
              to: obj.toStore.storeName,
              price:
                (org && org.curSymbol) +
                " " +
                (obj.items.length > 0
                  ? obj.items
                      .map((j) => j.qty * j.product.sellPrice)
                      .reduce((x, y) => x + y)
                      .toFixed(2)
                  : 0),
              userName: obj.user.userName,
            }))
          );
      };

      setLoader(true);
      _getById(
        endpoints.StockTrans + controller.GetByStore,
        selStore,
        null,
        onComplete
      );
    }
    return () => {
      _clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  const handleAddEdit = (val) => {
    navigate(`stock-transfer-form/${val.id}`);
  };

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: t("areYouSure"),
      subTitle: t("stockTransferDltTitle"),
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: t("dltSuccessTitle"),
              subTitle: res.msg,
            });
            setTblData((x) => [...x.filter((j) => j.id !== res.result.id)]);
          } else
            setAlert({
              isOpen: true,
              type: "error",
              title: t("error"),
              subTitle: res.msg,
            });
        };
        _delete(
          endpoints.StockTrans,
          id,
          actions.ActionType.RmvList,
          onComplete
        );
      },
    });
  };

  const handleView = (items) => {
    setViewItems(items);
    setShowForm(!showForm);
  };

  useEffect(() => {
    setCsvHeader([
      { key: "date", label: t("date") },
      { key: "from", label: t("locationFrom") },
      { key: "to", label: t("locationTo") },
      { key: "price", label: t("totalPrice") },
      { key: "userName", label: t("userName") },
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ActionComp = (props) => (
    <Box>
      <Tooltip arrow title={t("view")}>
        <IconButton
          onClick={() => handleView(props.items)}
          size="small"
          className="btn-primary mr-5"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title={t("delete")}>
        <IconButton
          onClick={() => handleDlt(props.id)}
          size="small"
          className="error-color"
        >
          <ClearRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </Box>
  );

  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      <Button
        onClick={handleAddEdit}
        className="btn-secondary"
        sx={{
          width: { md: "180px", xs: "100%" },
          mb: { xs: "10px", md: "0px" },
          fontSize: "17px",
          padding: "8px",
        }}
      >
        {t("addTransfer")}
      </Button>
      <Box
        className="d-flex"
        sx={{
          width: { xs: "100%", md: "auto" },
        }}
      >
        <CSVLink
          data={tblData}
          headers={csvHeader}
          filename={`${"stockTransfer"} (${new Date().toDateString()}).csv`}
          target="_blank"
          className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
        >
          {t("exportCSV")}
        </CSVLink>
      </Box>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: t("actions") },
          ...ChangeArrayKey(csvHeader, "key", "id"),
        ]}
        tblData={tblData}
      />
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />

      {/* Edit Dialog */}
      <Dialog show={showForm} maxWidth="lg" onClose={false}>
        <ViewData {...{ setShowForm, items: viewItems }} />
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  _list: state.pos.list,
  _type: state.pos.type,
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
  _clear: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(StockTransfers);
