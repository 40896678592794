import {
  AutoGraphRounded,
  SsidChartRounded,
  StackedLineChartRounded,
  WaterfallChartRounded,
} from "@mui/icons-material";
import { Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { controller, endpoints } from "../../api/actions/api";
import * as actions from "../../api/actions/pos";

const PeriodicalSalesReports = ({ _getById }) => {
  const { selStore } = useContext(AppContext);
  const { t } = useTranslation();
  const [periodicSell, setPeriodicSell] = useState();

  useEffect(() => {
    selStore &&
      _getById(
        endpoints.Dashboard + controller.PeriodicalSellByStore,
        selStore,
        null,
        (res) => res.status === 200 && setPeriodicSell(res.result)
      );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  return periodicSell ? (
    <Paper>
      <Grid container>
        <Grid item xs={12} md={6} lg={3}>
          <Widget
            title={t("yearlySales")}
            value={periodicSell.yearlySell.toLocaleString()}
            icon={<SsidChartRounded className="fs-50 primary-color" />}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Widget
            title={t("monthlySales")}
            value={periodicSell.monthlySell.toLocaleString()}
            icon={<StackedLineChartRounded className="fs-50 primary-color" />}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Widget
            title={t("weeklySales")}
            value={periodicSell.weeklySell.toLocaleString()}
            icon={<WaterfallChartRounded className="fs-50 primary-color" />}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Widget
            isBorder={false}
            title={t("dailySales")}
            value={periodicSell.todaysSell.toLocaleString()}
            icon={<AutoGraphRounded className="fs-50 primary-color" />}
          />
        </Grid>
      </Grid>
    </Paper>
  ) : (
    <span>Loading...</span>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.pos.authUser,
  _defStore: state.pos.defStore,
});

const mapActiontoProps = { _getById: actions.getById };

export default connect(
  mapStateToProps,
  mapActiontoProps
)(PeriodicalSalesReports);

const Widget = (props) => {
  const { title, value, icon, isBorder = true } = props;
  const { org } = useContext(AppContext);
  return (
    <Box
      className="d-flex a-center j-between p-40"
      sx={
        isBorder
          ? {
              borderRight: { md: "1px solid var(--graySM-color)" },
              borderBottom: {
                xs: "1px solid var(--graySM-color)",
                md: "none",
              },
            }
          : {}
      }
    >
      <div className="d-grid">
        <span className="fs-16 fw-5 gray-color mb-20">{title}</span>
        <div>
          <span className="fs-16">{org && org.curSymbol} </span>
          <span className=" fw-5 fs-26">{value}</span>
        </div>
      </div>
      <div>{icon}</div>
    </Box>
  );
};
