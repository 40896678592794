export default function Model() {
  const LoginMdl = () => ({
    userName: "",
    password: "",
  });

  const ForgotMdl = () => ({
    userName: "",
    email: "",
    forgotStep: "",
    verCode: "",
    password: "",
    confirmPass: "",
  });

  const CancelCartMdl = () => ({
    id: "",
    cancelItem: "",
    userId: "",
    storeId: "",
  });

  const CstmrMdl = () => ({
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    gender: "",
    dob: "",

    country: "",
    city: "",
    state: "",
    zip: "",
    address1: "",
    address2: "",

    cstmrCode: "",
    company: "",

    website: "",
    twitter: "",
    note: "",
    photo: "",
    photoFile: "",
    accBalType: "",
    balLimit: "",

    // card details
    nameOnCard: "",
    cardNumber: "",
    expiryDate: "",
    securityCode: "",
  });

  const OrgMdl = () => ({
    id: "",
    name: "",
    nameLocale: "",
    email: "",
    phone: "",
    currency: "",
    country: "",
    city: "",
    state: "",
    zip: "",
    address: "",
    userId: "",
    logo: "",
    logoFile: "",
  });

  const MakePayMdl = () => ({
    sellId: "",
    amount: "",
    nextDueDate: "",
  });

  const SellMdl = () => ({
    id: 0,
    sellItems: "",
    paymentType: "",
    taxPct: "",
    disAmt: "",
    subtotal: "",
    received: "",
    change: "",
    note: "",
    sellType: 1,
    dueAmt: "",
    totalPay: "",
    dueDate: "",
    creditAmt: "",
    changeAddedCredit: false,

    cstmrId: "",
    userId: "",
    storeId: "",
  });

  const VoidSellMdl = () => ({
    id: 0,
    items: "",
    subtotal: "",
    userId: "",
    storeId: "",
  });

  const ProdMdl = () => ({
    id: 0,
    name: "",
    barcode: "",
    categoryId: "",
    parentCatId: "",
    unit: "",
    tax: "",
    descr: "",
    purchPrice: "",
    profitMargin: "",
    sellPrice: "",
    alertQty: "",
    imgs: [],
    imgsFile: [],
  });

  const CatMdl = () => ({
    id: 0,
    name: "",
    descr: "",
    parentCatId: "",
  });

  const ExpsMdl = () => ({
    id: 0,
    amount: "",
    note: "",
    expenseCatId: "",
    addDate: "",
    addUser: "",
    storeId: "",
  });

  const PurchaseMdl = () => ({
    id: 0,
    supplierId: "",
    purchDate: new Date().toLocaleString(),
    paymentType: "",
    paymentDue: "",
    discount: "",
    purchItems: [],
    recept: "",
    receptFile: "",
    userId: "",
    storeId: "",
  });

  const PurchReturnMdl = () => ({
    id: 0,
    purchaseId: "",
    purchReturnItems: [],
    returnDate: new Date().toLocaleString(),
    userId: "",
  });

  const ExpensesMdl = () => ({
    id: 0,
    expsType: "",
    paybleTo: "",
    date: "",
    payment: "",
    note: "",
    doc: "",
    docFile: "",
  });

  const ExpsCatMdl = () => ({
    id: 0,
    name: "",
    descr: "",
  });

  const SuppliersMdl = () => ({
    id: 0,
    company: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    zip: "",
    address: "",
    productSale: "",
    note: "",
  });

  const UserMdl = () => ({
    id: 0,
    prefix: "",
    firstName: "",
    lastName: "",
    dob: "",
    ssn: "",
    gender: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    language: "",

    storeId: "",
    role: "",
    isActive: "",
    userName: "",
    password: "",
    confirmPass: "",

    salaryType: "",
    salary: "",
  });

  const StockTransMdl = () => ({
    id: 0,
    date: "",
    fromStoreId: "",
    toStoreId: "",
    note: "",
    items: "",
  });

  const InventoryMdl = () => ({
    id: 0,
    productId: "",
    storeId: "",
    inInventory: "",
  });

  const PaymentMdl = () => ({
    id: 0,
    fromDate: "",
    toDate: "",
    totalHours: "",
    totalSalary: "",
    payDate: "",
    userId: "",
  });

  const StoreMdl = () => ({
    id: 0,
    storeName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    tax: "",
    currency: "",

    firstName: "",
    lastName: "",
    dob: "",
    ssn: "",
    gender: "",
    mngAddress: "",
    mngCity: "",
    mngState: "",
    mngZip: "",
    mngPhone: "",

    logo: "",
    logoFile: "",
  });

  return {
    LoginMdl,
    ForgotMdl,
    ProdMdl,
    CatMdl,
    ExpsMdl,
    PurchaseMdl,
    PurchReturnMdl,
    ExpensesMdl,
    ExpsCatMdl,
    SuppliersMdl,
    UserMdl,
    StockTransMdl,
    InventoryMdl,
    PaymentMdl,
    StoreMdl,
    SellMdl,
    VoidSellMdl,
    CancelCartMdl,
    OrgMdl,
    CstmrMdl,
    MakePayMdl,
  };
}
