import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { Paper, Typography } from "@mui/material";
import moment from "moment";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";

const MonthlySalesChart = ({ sellsPerMonth = [] }) => {
  const { org } = useContext(AppContext);
  const { t } = useTranslation();

  const options = {
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: moment.monthsShort(),
    },
    yaxis: {
      title: {
        text: t("totalSales"),
        style: {
          color: "var(--gray-color)",
          fontFamily: "roboto",
        },
      },
      labels: {
        formatter: (val) => (org && org.curSymbol) + " " + val.toLocaleString(),
        style: {
          fontSize: "14px",
        },
      },
    },
    colors: ["#007bff"],
  };
  const series = [
    {
      name: t("sales"),
      data: sellsPerMonth,
    },
  ];

  return (
    <Paper className="p-15">
      {/* Chart */}
      <Typography varint="h6" className="fs-18 fw-5 gray-color mb-10">
        {t("monthlySalesReports")}
      </Typography>
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        width="100%"
        height="220"
      />
    </Paper>
  );
};

export default MonthlySalesChart;
