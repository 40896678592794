import { ClearRounded, EditRounded } from "@mui/icons-material";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, Table } from "../../layout";
import * as actions from "../../api/actions/pos";
import { useEffect } from "react";
import { endpoints } from "../../api/actions/api";
import { useContext } from "react";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import { ChangeArrayKey } from "../../hooks/Method";

const Stores = (props) => {
  const { _get, _delete, _store } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setLoader } = useContext(AppContext);
  const [csvHeader, setCsvHeader] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    const onComplete = (res) => {
      setLoader(false);
      res.status === 200 &&
        setTblData(
          res.result.map((x) => ({
            ...x,
            name: x.firstName + " " + x.lastName,
          }))
        );
    };

    setLoader(true);
    _get(endpoints.Store, null, onComplete);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddEdit = (val) => {
    navigate(`add-store-form/${val.id || ""}`);
  };

  const handleDlt = (id) => {
    if (_store.id === id) {
      setAlert({
        isOpen: true,
        type: "warning",
        title: t("switchToAnotherStore"),
        subTitle: t("storeDltWarning"),
      });
    } else {
      setAlert({
        isOpen: true,
        type: "confirmation",
        title: t("itsTooDangerous"),
        subTitle: t("storeDltTitle"),
        onConfirm: () => {
          const onComplete = (res) => {
            if (res.status === 200) {
              setAlert({
                isOpen: true,
                type: "success",
                title: t("dltSuccessTitle"),
                subTitle: res.msg,
              });
              setTblData((x) => [...x.filter((j) => j.id !== res.result.id)]);
            } else {
              setAlert({
                isOpen: true,
                type: "error",
                title: t("error"),
                subTitle: res.msg,
              });
            }
          };
          _delete(endpoints.Store, id, actions.ActionType.RmvStore, onComplete);
        },
      });
    }
  };

  useEffect(() => {
    setCsvHeader([
      { key: "storeName", label: t("storeName") },
      { key: "managerName", label: t("managerName") },
      { key: "address", label: t("address") },
      { key: "city", label: t("city") },
      { key: "state", label: t("state") },
      { key: "zip", label: t("ZIP") },
      { key: "phone", label: t("phone") },
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ActionComp = (props) => (
    <div className="bs-secondary br-10 p-5 w-fit">
      <Tooltip arrow title={t("edit")}>
        <IconButton
          onClick={() => handleAddEdit(props)}
          size="small"
          color="primary"
          className="mr-10"
        >
          <EditRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title={t("delete")}>
        <IconButton
          onClick={() => handleDlt(props.id)}
          size="small"
          color="error"
        >
          <ClearRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </div>
  );

  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      <Button
        onClick={handleAddEdit}
        sx={{
          width: { md: "180px", xs: "100%" },
          mb: { xs: "10px", md: "0px" },
          fontSize: "17px",
          padding: "8px",
        }}
      >
        {t("addStore")}
      </Button>

      <Box
        className="d-flex"
        sx={{
          width: { xs: "100%", md: "auto" },
        }}
      >
        <CSVLink
          data={tblData}
          headers={csvHeader}
          filename={`${t("store")} (${new Date().toDateString()}).csv`}
          target="_blank"
          className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
        >
          {t("exportCSV")}
        </CSVLink>
      </Box>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: t("actions") },
          ...ChangeArrayKey(csvHeader, "key", "id"),
        ]}
        tblData={tblData}
      />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _stores: state.pos.stores,
  _type: state.pos.type,
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _get: actions.get,
  _delete: actions.Delete,
  _clear: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(Stores);
