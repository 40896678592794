import React, { useContext, useEffect, useState } from "react";
import { Table } from "../../../layout";
import { InputDate } from "../../../controls";
import { subDays } from "date-fns";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../App";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/pos";
import { controller, endpoints } from "../../../api/actions/api";

const Accounts = (props) => {
  const { _getById, cstmr } = props;
  const { t } = useTranslation();
  const { org } = useContext(AppContext);
  const [tblData, setTblData] = useState([]);
  const [fltData, setFltData] = useState([]);
  const [sortDate, setSortDate] = useState({
    fromDate: `1/1/${new Date().getFullYear()}`,
    toDate: `12/1/${new Date().getFullYear()}`,
  });

  const handleDate = (name, newVal) => {
    setSortDate((x) => ({ ...x, [name]: newVal }));
  };

  useEffect(() => {
    // get customer creadit and debits
    _getById(
      endpoints.Customer + controller.CreditDebits,
      cstmr.id,
      null,
      (res) => res.status === 200 && setTblData([...res.result])
    );

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cstmr]);
  useEffect(() => {
    setFltData([
      ...tblData.filter(
        (j) =>
          moment(j.addDate, "MM/DD/YYYY") >=
            moment(sortDate.fromDate, "MM/DD/YYYY") &&
          moment(j.addDate, "MM/DD/YYYY") <=
            moment(sortDate.toDate, "MM/DD/YYYY")
      ),
    ]);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDate, tblData]);

  const HeaderComp = () => (
    <div>
      <div>
        <span></span>
      </div>
      <div className="d-flex a-center g-5 w-100pr">
        <InputDate
          variant="outlined"
          fullWidth
          size="small"
          name="fromDate"
          value={sortDate.fromDate}
          views={["day", "month", "year"]}
          openTo="day"
          minDate={new Date("01/01/2000")}
          maxDate={subDays(new Date(sortDate.toDate), 1)}
          onChange={(date) =>
            handleDate("fromDate", new Date(date).toLocaleDateString())
          }
        />
        <span className="ml-10 mr-10 fs-16 dark-color">TO</span>
        <InputDate
          variant="outlined"
          fullWidth
          size="small"
          name="toDate"
          value={sortDate.toDate}
          minDate={moment(sortDate.fromDate, "MM/DD/YYYY").add(1, "days")}
          views={["day", "month", "year"]}
          openTo="day"
          onChange={(date) =>
            handleDate("toDate", new Date(date).toLocaleDateString())
          }
        />
      </div>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={() => <></>}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "typeName", label: t("type") },
          { id: "trans", label: t("transaction") },
          {
            id: "amt",
            label: t("amount"),
          },
        ]}
        tblData={fltData.map((j) => {
          const typeName = j.type === 1 ? "Credit" : "Debit";
          return {
            ...j,
            title: j.title,
            type: typeName,
            typeName: (
              <span
                className={
                  (j.type === 1
                    ? "primary-color bg-primaryXS"
                    : "error-color bg-errorSM") +
                  " fw-5 fs-14 tx-upp p-10 br-20"
                }
              >
                {typeName}
              </span>
            ),
            trans: (
              <div className="d-grid g-5">
                <span className="gray-color fw-5">{j.addDate}</span>
                <span className="gray-color fs-14">{j.title}</span>
              </div>
            ),
            amt: j.amount ? (
              <span
                className={
                  (j.type === 1 ? "primary-color" : "error-color") + " fs-18"
                }
              >
                {org && org.curSymbol + " " + j.amount}
              </span>
            ) : (
              ""
            ),
          };
        })}
      />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(Accounts);
