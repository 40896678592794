import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import { Table } from "../../layout";
import * as actions from "../../api/actions/pos";
import { AppContext } from "../../App";
import { useEffect } from "react";
import { controller, endpoints } from "../../api/actions/api";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { CSVLink } from "react-csv";
import { ChangeArrayKey } from "../../hooks/Method";

const TopSelling = (props) => {
  const { _getById } = props;
  const { setLoader, selStore, org } = useContext(AppContext);
  const { t } = useTranslation();
  const [sells, setSells] = useState([]);
  const [csvHeader, setCsvHeader] = useState([]);
  const [tblData, setTblData] = useState([]);

  const onComplete = (res) => {
    if (res.status === 200 && res.result.length > 0) {
      const filteredProds = [];
      const _sells = res.result
        .map((j) =>
          j.sellItems.map((y) => {
            return { ...y, sellDate: j.sellDate };
          })
        )
        .flat();
      const _prods = _sells.filter((j) => j.itemType === 1);
      // Push Lottery Item
      filteredProds.push(_sells.filter((j) => j.itemType === 2));

      // Push Product
      _prods.forEach((j) => {
        var prod = _prods.filter((x) => x.product.id === j.product.id);
        // Check the product list is exist in the list before push to the list.
        !filteredProds.some(
          (x) => JSON.stringify(x) === JSON.stringify(prod)
        ) && filteredProds.push(prod);
      });

      setSells(
        filteredProds.sort(
          (f, s) =>
            s.map((j) => j.qty).reduce((x, y) => x + y, 0) -
            f.map((j) => j.qty).reduce((x, y) => x + y, 0)
        )
      );
      setLoader(false);
    } else setLoader(false);
  };

  useEffect(() => {
    if (selStore) {
      setLoader(true);
      _getById(
        endpoints.Sell + controller.GetSellsByStore,
        selStore,
        null,
        onComplete
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  useEffect(() => {
    setCsvHeader([
      { key: "name", label: t("productName") },
      { key: "category", label: t("cateogry") },
      { key: "itemSold", label: t("itemSold") },
      { key: "totalSale", label: t("totalSale") },
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTblData([
      ...sells
        .filter((j) => j.length > 0)
        .map((item) => {
          return {
            ...item,
            name: item[0].itemType === 1 ? item[0].product.name : t("lottery"),
            category:
              item[0].itemType === 1 && item[0].product.category !== null
                ? item[0].product.category.name
                : "",
            itemSold: item.map((j) => j.qty).reduce((x, y) => x + y, 0),
            totalSale:
              (org && org.curSymbol) +
              " " +
              item
                .map((j) => j.qty * j.price)
                .reduce((x, y) => x + y, 0)
                .toLocaleString(),
          };
        }),
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sells]);

  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      <Box
        className="d-flex"
        sx={{
          width: { xs: "100%", md: "auto" },
        }}
      >
        <CSVLink
          data={tblData}
          headers={csvHeader}
          filename={`${t("topSelling")} (${new Date().toDateString()}).csv`}
          target="_blank"
          className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
        >
          {t("exportCSV")}
        </CSVLink>
      </Box>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={() => <div></div>}
        HeaderComp={HeaderComp}
        tblHead={[...ChangeArrayKey(csvHeader, "key", "id")]}
        tblData={tblData}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(TopSelling);
