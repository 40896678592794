import { Grid } from "@mui/material";
import React from "react";
import * as actions from "../../api/actions/pos";
import { connect } from "react-redux";
import {
  SalesAccWidget,
  SalesChart,
  PeriodicalSalesReports,
  PurchaseAndSellChart,
  StoreValueReport,
  TopTenItems,
  SalesByEmployee,
  ProfitBreakdown,
  TopSellingProdTable,
  StoreExpenses,
} from "../index";
import { useContext } from "react";
import { AppContext } from "../../App";
import { useState } from "react";
import { useEffect } from "react";
import { controller, endpoints } from "../../api/actions/api";

const Home = (props) => {
  const { _getById } = props;
  const { selStore } = useContext(AppContext);
  const [sellsPerMonth, setSellsPerMonth] = useState([]);
  const [purchPerMonth, setPurchPerMonth] = useState([]);

  useEffect(() => {
    if (selStore) {
      // Get sell per month
      _getById(
        endpoints.Dashboard + controller.SellsPerMonth,
        selStore,
        null,
        (res) => res.status === 200 && setSellsPerMonth(res.result)
      );

      // Get purchase per month
      _getById(
        endpoints.Dashboard + controller.PurchasePerMonth,
        selStore,
        null,
        (res) => res.status === 200 && setPurchPerMonth(res.result)
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  return (
    <Grid container spacing={3} className="tx-upp">
      <Grid item xs={12}>
        <PeriodicalSalesReports />
      </Grid>
      <Grid item xs={12} md={5}>
        <SalesAccWidget />
      </Grid>
      <Grid item xs={12} md={7}>
        <SalesChart {...{ sellsPerMonth }} />
      </Grid>
      <Grid item xs={12} md={9}>
        <PurchaseAndSellChart {...{ sellsPerMonth, purchPerMonth }} />
      </Grid>
      <Grid item xs={12} md={3}>
        <StoreValueReport />
      </Grid>
      <Grid item xs={12}>
        <TopTenItems />
      </Grid>
      <Grid item xs={12} md={4}>
        <SalesByEmployee />
      </Grid>
      <Grid item xs={12} md={4}>
        <ProfitBreakdown />
      </Grid>
      <Grid item xs={12} md={4}>
        <StoreExpenses />
      </Grid>
      <Grid item xs={12}>
        <TopSellingProdTable />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getById: actions.getById,
  _get: actions.get,
};

export default connect(mapStateToProps, mapActionToProps)(Home);
