import { ClearRounded, EditRounded } from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Alert, Dialog, Table } from "../../../layout";
import AddEditBills from "./AddEditBills";
import * as actions from "../../../api/actions/pos";
import { useContext } from "react";
import { AppContext } from "../../../App";
import { useEffect } from "react";
import { controller, endpoints } from "../../../api/actions/api";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import { ChangeArrayKey } from "../../../hooks/Method";

const PayBills = (props) => {
  const { _getById, _delete } = props;
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const [updData, setUpdData] = useState();
  const { setLoader, selStore, org } = useContext(AppContext);
  const [csvHeader, setCsvHeader] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (selStore) {
      const onComplete = (res) => {
        setLoader(false);
        res.status === 200 &&
          setTblData(
            res.result.map((obj) => ({
              ...obj,
              payType: obj.expenseCat.name,
              store: obj.store.storeName,
              amount: (org && org.curSymbol) + " " + obj.amount,
            }))
          );
      };

      setLoader(true);
      _getById(
        endpoints.Expense + controller.GetByStoreId,
        selStore,
        null,
        onComplete
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  const handleAddEdit = (val) => {
    setUpdData(val);
    setShowForm(true);
  };

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: t("areYouSure"),
      subTitle: t("dltTitle"),
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: t("dltSuccessTitle"),
              subTitle: res.msg,
            });
            setTblData((x) => [...x.filter((j) => j.id !== res.result.id)]);
          } else
            setAlert({
              isOpen: true,
              type: "error",
              title: t("error"),
              subTitle: res.msg,
            });
        };
        _delete(endpoints.Expense, id, actions.ActionType.RmvList, onComplete);
      },
    });
  };

  useEffect(() => {
    setCsvHeader([
      { key: "payType", label: t("payType") },
      { key: "store", label: t("store") },
      { key: "amount", label: t("total") },
      { key: "note", label: t("note") },
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      <Button
        onClick={() => handleAddEdit()}
        className="btn-secondary"
        sx={{
          width: { md: "180px", xs: "100%" },
          mb: { xs: "10px", md: "0px" },
          fontSize: "17px",
          padding: "8px",
        }}
      >
        {t("addPayBill")}
      </Button>
      <Box
        className="d-flex"
        sx={{
          width: { xs: "100%", md: "auto" },
        }}
      >
        <CSVLink
          data={tblData}
          headers={csvHeader}
          filename={`${t("payBills")} (${new Date().toDateString()}).csv`}
          target="_blank"
          className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
        >
          {t("exportCSV")}
        </CSVLink>
      </Box>
    </div>
  );

  const ActionComp = (props) => (
    <Box>
      <Tooltip arrow title={t("edit")}>
        <IconButton
          onClick={() => handleAddEdit(props)}
          size="small"
          className="btn-primary mr-5"
        >
          <EditRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title={t("delete")}>
        <IconButton
          onClick={() => handleDlt(props.id)}
          size="small"
          className="error-color"
        >
          <ClearRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </Box>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: t("actions") },
          ...ChangeArrayKey(csvHeader, "key", "id"),
        ]}
        tblData={tblData}
      />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />

      {/* Edit Dialog */}
      {showForm && (
        <Dialog show={showForm} onClose={false}>
          <AddEditBills {...{ setShowForm, updData }} />
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  _list: state.pos.list,
  _type: state.pos.type,
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
  _clear: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(PayBills);
