import React from "react";
import { Box, Grid } from "@mui/material";
import {
  Categories,
  Products,
  Actions,
  Cart,
  Checkout,
  Lottery,
  Refund,
} from "./";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useState } from "react";
import PrintRecept from "./PrintRecept";
import { Alert, Dialog } from "../../layout";
import { useEffect } from "react";
import CashInDrawer from "./CashInDrawer";

const Pos = () => {
  const [cartItems, setCartItems] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [totalPay, setTotalPay] = useState(0);
  const [tax, setTax] = useState(0);
  const [receptData, setReceptData] = useState({});
  const [drawerForm, setDrawerForm] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const navigate = useNavigate();

  const handleClearCart = () => {
    setCartItems([]);
    setSubTotal(0);
    setTotalPay(0);
    setTax(0);
    setReceptData({});
    navigate("/pos");
  };

  useEffect(() => {
    // Show cash in drawer form.
    if (!("posOpen" in localStorage)) setDrawerForm(true);
    return () => {};
  }, []);

  return (
    <>
      <Box className="d-none" id="printSellRecept">
        <PrintRecept data={receptData} />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} xl={7}>
          <Box>
            {/* Body */}
            <Box
              sx={{
                height: {
                  sm: "calc(100vh - 280px)",
                  xl: "calc(100vh - 240px)",
                },
                overflow: "auto",
                borderRadius: "0.8rem",
              }}
            >
              <Routes>
                <Route
                  path="lottery"
                  element={<Lottery {...{ cartItems, setCartItems }} />}
                />
                <Route
                  path="refund"
                  element={
                    <Refund
                      {...{
                        cartItems,
                        tax,
                        subTotal,
                        setAlert,
                        handleClearCart,
                      }}
                    />
                  }
                />

                <Route
                  path="checkout"
                  element={
                    <Checkout
                      {...{
                        subTotal,
                        cartItems,
                        totalPay,
                        setAlert,
                        tax,
                        setReceptData,
                        handleClearCart,
                      }}
                    />
                  }
                />
                <Route path="products">
                  <Route
                    path=":id"
                    element={
                      <Products {...{ cartItems, setCartItems, setAlert }} />
                    }
                  />
                  <Route
                    path=""
                    element={<Products {...{ cartItems, setCartItems }} />}
                  />
                </Route>
                <Route path="categories" element={<Categories />} />
                <Route
                  index
                  element={<Products {...{ cartItems, setCartItems }} />}
                />
              </Routes>
            </Box>
          </Box>
          {/* Actions */}
          <Actions
            {...{
              setReceptData,
              setAlert,
              cartItems,
              subTotal,
              tax,
              handleClearCart,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} xl={5}>
          <Cart
            {...{
              cartItems,
              setCartItems,
              subTotal,
              setSubTotal,
              totalPay,
              setTotalPay,
              tax,
              setTax,
            }}
          />
        </Grid>
      </Grid>
      {/* Edit Dialog */}
      {drawerForm && (
        <Dialog show={drawerForm} onClose={false} maxWidth="xs">
          <CashInDrawer {...{ setShow: setDrawerForm }} />
        </Dialog>
      )}
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default Pos;
