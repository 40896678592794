import { VisibilityRounded } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Alert, Dialog, Table } from "../../layout";
import ViewProd from "./ViewProd";
import * as actions from "../../api/actions/pos";
import { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "../../App";
import { controller, endpoints } from "../../api/actions/api";
import { DropLi } from "../../hooks";
import { ChangeArrayKey, IsEmptyObj } from "../../hooks/Method";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";

const Sells = (props) => {
  const { _getById } = props;
  const { t } = useTranslation();
  const { setLoader, selStore, org } = useContext(AppContext);
  const { PayType } = DropLi();
  const [viewData, setViewData] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [csvHeader, setCsvHeader] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (selStore) {
      const onComplete = (res) => {
        setLoader(false);
        res.status === 200 &&
          setTblData(
            res.result.map((obj) => {
              const paymentType = PayType.find((j) => j.id === obj.paymentType);
              return {
                ...obj,
                sellType: obj.sellType === 1 ? "Paid" : "Due",
                totalItem: obj.sellItems.length,
                paymentType: paymentType && paymentType.label,
                subtotal: org && org.curSymbol + " " + obj.subtotal,
                received: org && org.curSymbol + " " + obj.received,
                change: org && org.curSymbol + " " + obj.change,
                username: obj.user.firstName + " " + obj.user.lastName,
              };
            })
          );
      };

      setLoader(true);
      _getById(
        endpoints.Sell + controller.GetSellsByStore,
        selStore,
        null,
        onComplete
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  useEffect(() => {
    setCsvHeader([
      { key: "sellType", label: t("sellType") },
      { key: "sellDate", label: t("transectionDate") },
      { key: "id", label: t("invoice") },
      { key: "totalItem", label: t("totalItem") },
      { key: "paymentType", label: t("paymentType") },
      { key: "subtotal", label: t("subtotal") },
      { key: "received", label: t("paymentReceived") },
      { key: "change", label: t("change") },
      { key: "username", label: t("cashier") },
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleView = (data) => {
    setViewData(data);
    setShowForm(!showForm);
  };

  const ActionComp = (props) => (
    <Box>
      <Tooltip arrow title={t("view")}>
        <IconButton
          onClick={() => handleView(props)}
          size="small"
          className="btn-primary mr-5"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </Box>
  );

  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      <Box
        className="d-flex"
        sx={{
          width: { xs: "100%", md: "auto" },
        }}
      >
        <CSVLink
          data={tblData}
          headers={csvHeader}
          filename={`${t("sells")} (${new Date().toDateString()}).csv`}
          target="_blank"
          className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
        >
          {t("exportCSV")}
        </CSVLink>
      </Box>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: t("actions") },
          ...ChangeArrayKey(csvHeader, "key", "id"),
        ]}
        tblData={tblData}
      />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />

      {/* Edit Dialog */}
      <Dialog show={showForm} maxWidth="lg" onClose={false}>
        {!IsEmptyObj(viewData) && <ViewProd {...{ setShowForm, viewData }} />}
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  _list: state.pos.list,
  _type: state.pos.type,
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
  _clear: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(Sells);
