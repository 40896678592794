import {
  Button,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { controller, endpoints } from "../../api/actions/api";
import * as actions from "../../api/actions/pos";
import { AppContext } from "../../App";
import { Input } from "../../controls";
import { IsEmptyObj, RmvEmptyProp, ToFormData } from "../../hooks/Method";
import { Alert } from "../../layout";
import { useTranslation } from "react-i18next";

const CloseReg = (props) => {
  const { setShowForm, _authUser, _post, _getByObj } = props;
  const { t } = useTranslation();
  const { setLoader, org } = useContext(AppContext);
  const navigate = useNavigate();

  const [sells, setSells] = useState([]);
  const [cancelCarts, setCancelCarts] = useState([]);
  const [voidSells, setVoidSells] = useState([]);
  const [openData, setOpenData] = useState({});
  const [totalSells, setTotalSells] = useState(0);
  const [average, setAverage] = useState(0);
  const [refund, setRefund] = useState(0);
  const [voidAmount, setVoidAmount] = useState(0);
  const [cashSale, setCashSale] = useState(0);
  const [craditSale, setCraditSale] = useState(0);
  const [taxItem, setTaxItem] = useState(0);
  const [nonTaxItem, setNonTaxItem] = useState(0);
  const [taxReceived, setTaxReceived] = useState(0);
  const [otherPayment, setOtherPayment] = useState(0);
  const [expense, setExpense] = useState([]);
  const [soldByCats, setSoldByCats] = useState([]);
  const [notes, setNotes] = useState("");
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const { selStore, stores } = useContext(AppContext);
  const [store, setStore] = useState({});

  useEffect(() => {
    setStore(stores.find((j) => j.id === selStore));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  useEffect(() => {
    if ("posOpen" in localStorage) {
      const posOpen = JSON.parse(localStorage.getItem("posOpen"));
      setOpenData(posOpen);
      setLoader(true);
      const onComplete = (res) => {
        if (res.status === 200) {
          setSells(res.result.sells);
          setVoidSells(res.result.voidSells);
          setCancelCarts(res.result.cancelCarts);
          setExpense(res.result.expense);
        }
        setLoader(false);
      };
      _getByObj(
        endpoints.Sell + controller.CloseRegSells,
        {
          userId: _authUser.id,
          storeId: selStore,
          value: posOpen.timeIn,
        },
        null,
        onComplete
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sells.length > 0) {
      var _total = sells
        .map((j) => j.subtotal + j.tax)
        .reduce((x, y) => x + y, 0);
      setTotalSells(_total.toFixed(2));
      setAverage((_total / sells.length).toFixed(2));

      var _taxItem = sells
        .map(
          (j) =>
            j.sellItems.filter((x) => x.itemType === 1 && x.product.tax === 1)
              .length
        )
        .reduce((x, y) => x + y, 0);
      setTaxItem(_taxItem);

      var _nonTaxItem = sells
        .map(
          (j) =>
            j.sellItems.filter((x) => x.itemType === 2 || x.product.tax === 2)
              .length
        )
        .reduce((x, y) => x + y, 0);
      setNonTaxItem(_nonTaxItem);

      var _taxReceived = sells.map((j) => j.tax).reduce((x, y) => x + y, 0);
      setTaxReceived(_taxReceived.toFixed(2));

      var _totalCashSale = sells
        .filter((j) => j.paymentType === 1)
        .map((j) => j.subtotal + j.tax)
        .reduce((x, y) => x + y, 0);
      setCashSale(_totalCashSale.toFixed(2));

      var _totalCraditSale = sells
        .filter((j) => j.paymentType === 2)
        .map((j) => j.subtotal + j.tax)
        .reduce((x, y) => x + y, 0);
      setCraditSale(_totalCraditSale.toFixed(2));

      var _otherPayment = sells
        .filter((j) => j.paymentType === 3)
        .map((j) => j.subtotal + j.tax)
        .reduce((x, y) => x + y, 0);
      setOtherPayment(_otherPayment.toFixed(2));

      var _refunds = voidSells
        .filter((j) => j.cancelType === 2)
        .map((j) => j.subtotal + j.tax)
        .reduce((x, y) => x + y, 0);
      setRefund(_refunds.toFixed(2));

      var _voidAmount = voidSells
        .filter((j) => j.cancelType === 1)
        .map((j) => j.subtotal + j.tax)
        .reduce((x, y) => x + y, 0);
      setVoidAmount(_voidAmount.toFixed(2));

      // Sold By Categories
      const _soldByCats = [];
      // Lottery
      const lottery = sells
        .map((j) => j.sellItems.filter((x) => x.itemType === 2))
        .flat();
      lottery.length > 0 && _soldByCats.push(lottery);
      // Product
      const prod = sells
        .map((j) => j.sellItems.filter((x) => x.itemType === 1))
        .flat();

      prod.forEach((j) => {
        var item = prod.filter(
          (x) => x.product.categoryId === j.product.categoryId
        );
        !_soldByCats.some((x) => JSON.stringify(x) === JSON.stringify(item)) &&
          _soldByCats.push(item);
      });
      setSoldByCats(_soldByCats);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sells]);

  const onComplete = (res) => {
    res.status === 201 &&
      setAlert({
        isOpen: true,
        type: "success",
        title: t("success"),
        subTitle: t("regClosedSuccessTitle"),
        onClick: () => {
          localStorage.removeItem("posOpen");
          setShowForm(false);
          navigate("/");
        },
      });
  };

  const handleClose = () => {
    if (!IsEmptyObj(openData)) {
      const regData = {
        id: 0,
        storeId: store.id,
        userId: _authUser.id,
        openTime: openData.timeIn,
        totalSale: totalSells,
        totalCash: cashSale,
        totalCradit: craditSale,
        totalRefund: refund,
        totalVoidSell: voidAmount,
        itemCancel: cancelCarts.length,
        notes: notes,
        otherPayment: otherPayment,
        taxItemSold: taxItem,
        nonTaxItemSold: nonTaxItem,
        taxReceived: taxReceived,
        averagePerCstmr: average,
        totalCstmr: sells.length,
        shiftTransBalanace: openData.cashInDrawer,
      };
      _post(
        endpoints.Register,
        ToFormData(RmvEmptyProp(regData)),
        null,
        onComplete
      );
    } else {
      setAlert({
        isOpen: true,
        type: "warning",
        title: t("warning"),
        subTitle: t("regCloseWarning"),
      });
    }
  };

  return (
    <>
      <Paper
        className="br-8 shadow-xs"
        sx={{ padding: { xs: "10px", md: "20px" } }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} className="tx-center mt-20">
            <Typography
              variant="h6"
              sx={{ fontSize: { md: "20px", xl: "26px" } }}
            >
              {t("cashRegisterReport")}
            </Typography>
            <div className="bs-bottom mx-w-150 m-auto mt-10 mb-30" />
          </Grid>
          <Grid item xs={12}>
            <Box
              className="d-flex j-between"
              sx={{ flexWrap: { xs: "wrap", md: "inherit" } }}
            >
              {/* Cashier Details */}
              <Box className="mt-20 mb-30 w-100pr" sx={{ mr: { md: "20px" } }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: { xs: "16px", md: "18px" },
                    borderLeft: "0.4rem double var(--primary-color)",
                    paddingLeft: "10px",
                  }}
                >
                  {t("cashierDetails")}
                </Typography>
                <Divider className="pt-10 mb-5" />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-6"
                    sx={{ minWidth: { xs: "140px", md: "180px" } }}
                  >
                    {t("storeName")}:
                  </Typography>
                  <Typography variant="subtitle1">{store.storeName}</Typography>
                </Box>
                <Divider />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-6"
                    sx={{ minWidth: { xs: "140px", md: "180px" } }}
                  >
                    {t("address")}:
                  </Typography>
                  <Typography variant="subtitle1">{store.address}</Typography>
                </Box>
                <Divider />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-6"
                    sx={{ minWidth: { xs: "140px", md: "180px" } }}
                  >
                    {t("cashierName")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {_authUser.firstName + " " + _authUser.lastName}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-6"
                    sx={{ minWidth: { xs: "140px", md: "180px" } }}
                  >
                    {t("timeIn")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {new Date(openData.timeIn).toLocaleTimeString()}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-6"
                    sx={{ minWidth: { xs: "140px", md: "180px" } }}
                  >
                    {t("timeOut")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {new Date().toLocaleTimeString()}
                  </Typography>
                </Box>
                <Divider />
              </Box>
              {/* Register Details */}
              <Box className="mt-20 mb-30 w-100pr">
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: { xs: "16px", md: "18px" },
                    borderLeft: "0.4rem double var(--primary-color)",
                    paddingLeft: "10px",
                  }}
                >
                  {t("registerDetails")}
                </Typography>
                <Divider className="pt-10 mb-5" />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-6"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalCustomers")}:
                  </Typography>
                  <Typography variant="subtitle1">{sells.length}</Typography>
                </Box>
                <Divider />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-6"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("averagePerCustomer")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {org && org.curSymbol} {average}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-6"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalSaleRefund")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {org && org.curSymbol} {refund}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-6"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalVoidSells")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {(org && org.curSymbol) + " " + voidAmount}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-6"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalItemCanceled")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {cancelCarts.length}
                  </Typography>
                </Box>
                <Divider />
              </Box>
            </Box>

            <Box
              className="d-flex j-between"
              sx={{ flexWrap: { xs: "wrap", md: "inherit" } }}
            >
              {/* Sale Report */}
              <Box className="mt-20 mb-30 w-100pr" sx={{ mr: { md: "20px" } }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: { xs: "16px", md: "18px" },
                    borderLeft: "0.4rem double var(--primary-color)",
                    paddingLeft: "10px",
                  }}
                >
                  {t("saleReport")}
                </Typography>
                <Divider className="pt-10 mb-5" />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-6"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalSale")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {(org && org.curSymbol) + " " + totalSells}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-6"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalCashSale")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {(org && org.curSymbol) + " " + cashSale}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-6"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalCreditSale")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {(org && org.curSymbol) + " " + craditSale}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-6"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("otherPaymentReceived")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {" "}
                    {(org && org.curSymbol) + " " + otherPayment}
                  </Typography>
                </Box>
              </Box>
              {/* Sell Details */}
              <Box className="mt-20 mb-30 w-100pr">
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: { xs: "16px", md: "18px" },
                    borderLeft: "0.4rem double var(--primary-color)",
                    paddingLeft: "10px",
                  }}
                >
                  {t("sellDetails")}
                </Typography>
                <Divider className="pt-10 mb-5" />

                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-6"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalGrossSale")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {(org && org.curSymbol) + " " + totalSells}
                  </Typography>
                </Box>
                <Divider />

                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-6"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalTaxItemSold")}:
                  </Typography>
                  <Typography variant="subtitle1">{taxItem}</Typography>
                </Box>

                <Divider />

                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-6"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalNonTaxItemSold")}:
                  </Typography>
                  <Typography variant="subtitle1">{nonTaxItem}</Typography>
                </Box>

                <Divider />

                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-6"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalTaxReceived")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {(org && org.curSymbol) + " " + taxReceived}
                  </Typography>
                </Box>
              </Box>
              <Divider />
            </Box>
            <Box
              className="d-flex j-between"
              sx={{ flexWrap: { xs: "wrap", md: "inherit" } }}
            >
              {/* Money */}
              <Box className="mt-20 mb-30 w-100pr" sx={{ mr: { md: "20px" } }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: { xs: "16px", md: "18px" },
                    borderLeft: "0.4rem double var(--primary-color)",
                    paddingLeft: "10px",
                  }}
                >
                  {t("money")}
                </Typography>
                <Divider className="pt-10 mb-5" />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-6"
                    sx={{ minWidth: { xs: "220px", md: "250px" } }}
                  >
                    {t("totalCashSale")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {(org && org.curSymbol) + " " + totalSells}
                  </Typography>
                </Box>
                <Divider />
                {expense.map((exp, i) => (
                  <Box key={i}>
                    <Box className="d-flex">
                      <Typography
                        variant="subtitle1"
                        className="fw-6"
                        sx={{ minWidth: { xs: "220px", md: "250px" } }}
                      >
                        {exp.expenseCat.name}:
                      </Typography>
                      <Typography variant="subtitle1">
                        {(org && org.curSymbol) + " " + exp.amount}
                      </Typography>
                    </Box>
                    <Divider />
                  </Box>
                ))}
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-6"
                    sx={{ minWidth: { xs: "220px", md: "250px" } }}
                  >
                    {t("shiftTransactionsBalance")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {" "}
                    -{(org && org.curSymbol) + " " + openData.cashInDrawer}
                  </Typography>
                </Box>
                <Divider />
              </Box>
            </Box>

            {/* Products Sold by Category */}
            <TableContainer className="mt-20">
              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: "16px", md: "18px" },
                  borderLeft: "0.4rem double var(--primary-color)",
                  paddingLeft: "10px",
                  marginBottom: "10px",
                }}
              >
                {t("productsSoldByCategory")}
              </Typography>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { padding: "0px" },
                    }}
                  >
                    <TableCell>{t("categoryName")}</TableCell>
                    <TableCell>{t("quantity")}</TableCell>
                    <TableCell>{t("price")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {soldByCats.map((item, i) => (
                    <TableRow key={i} sx={{ "td, th": { padding: "5px 0px" } }}>
                      <TableCell>
                        {item[0].itemType === 1
                          ? item[0].product.category !== null
                            ? item[0].product.category.name
                            : t("others")
                          : t("lottery")}
                      </TableCell>
                      <TableCell>
                        {item.map((j) => j.qty).reduce((x, y) => x + y, 0)}
                      </TableCell>
                      <TableCell>
                        {(org && org.curSymbol) +
                          " " +
                          item
                            .map((j) => j.qty * j.price)
                            .reduce((x, y) => x + y, 0)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Details of products sold */}
            <TableContainer className="mt-20">
              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: "16px", md: "18px" },
                  borderLeft: "0.4rem double var(--primary-color)",
                  paddingLeft: "10px",
                  marginBottom: "10px",
                }}
              >
                {t("detailsOfProductsSold")}:
              </Typography>
              <Box sx={{ maxHeight: "300px !important", overflow: "auto" }}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { padding: "0px" },
                      }}
                    >
                      <TableCell>{t("itemName")}</TableCell>
                      <TableCell className="w-70">{t("quantity")}</TableCell>
                      <TableCell className="w-90">{t("tax")}</TableCell>
                      <TableCell>{t("price")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sells
                      .map((j) => j.sellItems)
                      .flat()
                      .map((item, i) => (
                        <TableRow
                          sx={{ "td, th": { padding: "5px 0px" } }}
                          key={i}
                        >
                          <TableCell>
                            {item.itemType === 1
                              ? item.product.name
                              : t("lottery")}
                          </TableCell>
                          <TableCell>{item.qty}</TableCell>
                          <TableCell>
                            {item.itemType === 1 && item.product.tax === 1
                              ? `${
                                  (org && org.curSymbol) +
                                  " " +
                                  (
                                    (parseInt(store.tax) / 100) *
                                    (item.price * item.qty)
                                  ).toFixed(2)
                                }`
                              : t("noTax")}
                          </TableCell>
                          <TableCell>
                            {(org && org.curSymbol) +
                              " " +
                              item.qty * item.price}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </TableContainer>
          </Grid>

          <Grid item xs={12}>
            <Input
              label={t("notes")}
              name="notes"
              value={notes}
              multiline
              rows={3}
              inputProps={{ maxLength: 399 }}
              onChange={(e) => setNotes(e.target.value)}
              fullWidth
            />
          </Grid>

          {/* Action Button */}
          <Grid
            item
            xs={12}
            className="tx-right d-flex mt-10"
            sx={{ flexWrap: { xs: "wrap", md: "initial" } }}
          >
            <Button
              onClick={handleClose}
              className="w-100pr"
              size="large"
              sx={{ mr: { md: "10px" }, mb: { xs: "15px", md: "0px" } }}
            >
              {t("closeRegister")}
            </Button>
            <Button
              onClick={() => setShowForm(false)}
              className="error-color bg-errorSM w-100pr"
              size="large"
            >
              {t("close")}
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _store: state.pos.defStore,
  _authUser: state.pos.authUser,
});

const mapActionToProps = {
  _post: actions.post,
  _getByObj: actions.getByObj,
  _clear: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(CloseReg);
