import {
  ClearRounded,
  EditRounded,
  HorizontalSplitRounded,
} from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, Dialog, Table } from "../../layout";
import * as actions from "../../api/actions/pos";
import { controller, endpoints } from "../../api/actions/api";
import { useContext } from "react";
import { AppContext } from "../../App";
import { DropLi } from "../../hooks";
import BarcodeGen from "./BarcodeGen";
import { useTranslation } from "react-i18next";
import UplProdList from "./UplProdList";
import { Button } from "../../controls";
import { CSVLink } from "react-csv";

const Products = (props) => {
  const { _getById, _delete } = props;
  const { t } = useTranslation();
  const { UnitType, BoolType } = DropLi();
  const [showCsvUpl, setShowCsvUpl] = useState(false);
  const navigate = useNavigate();
  const { selStore, setLoader, org } = useContext(AppContext);
  const [barCode, setBarCode] = useState("abcd");
  const [prods, setProds] = useState([]);
  const [showBarcode, setShowBarcode] = useState(false);
  const [csvHeader, setCsvHeader] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: t("areYouSure"),
      subTitle: t("prodDltTitle"),
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: t("dltSuccessTitle"),
              subTitle: res.msg,
            });
            setProds((x) => [...x.filter((j) => j.id !== res.result.id)]);
          } else
            setAlert({
              isOpen: true,
              type: "error",
              title: t("error"),
              subTitle: res.msg,
            });
        };
        _delete(endpoints.Product, id, null, onComplete);
      },
    });
  };

  const handleBarcode = (barcode) => {
    setBarCode(barcode);
    setShowBarcode(true);
  };

  useEffect(() => {
    if (selStore) {
      setLoader(true);
      _getById(
        endpoints.Product + controller.GetByStore,
        selStore,
        null,
        (res) => {
          setLoader(false);
          setProds([...res.result]);
        }
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  useEffect(() => {
    setCsvHeader([
      { key: "id", label: t("id") },
      { key: "name", label: t("name") },
      { key: "barcode", label: t("barcode") },
      { key: "category", label: t("category") },
      { key: "subcategory", label: t("subcategory") },
      { key: "unitType", label: t("unitType") },
      { key: "tax", label: t("tax") },
      { key: "purchPrice", label: t("purchasePrice") },
      { key: "profitMargin", label: t("profitMargin") },
      { key: "sellPrice", label: t("sellPrice") },
      { key: "alertQty", label: t("alertQty") },
    ]);

    return () => {
      setCsvHeader();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTblData([
      ...prods.map((obj) => ({
        ...obj,
        category: obj.category !== null ? obj.category.name : "",
        subCat: obj.parentCat !== null ? obj.parentCat.name : "",
        unit: UnitType.find((j) => j.id === obj.unit).label,
        tax: BoolType.find((j) => j.id === obj.tax).label,
        profitMargin: obj.profitMargin && obj.profitMargin + "%",
        purchPrice: org && org.curSymbol + " " + obj.purchPrice,
        sellPrice: org && org.curSymbol + " " + obj.sellPrice,
      })),
    ]);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prods]);

  const handleImportExcel = () => {
    setShowCsvUpl(true);
  };

  const ActionComp = (props) => (
    <Box>
      <Tooltip arrow title={t("printBarcode")}>
        <IconButton
          onClick={() => handleBarcode(props.barcode)}
          size="small"
          className="btn-primary mr-5"
        >
          <HorizontalSplitRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title={t("edit")}>
        <IconButton
          onClick={() => navigate(`product-form/${props.id}`)}
          size="small"
          className="btn-primary mr-5"
        >
          <EditRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title={t("delete")}>
        <IconButton
          onClick={() => handleDlt(props.id)}
          size="small"
          color="error"
        >
          <ClearRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </Box>
  );

  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      <Button
        onClick={() => navigate("product-form")}
        variant="text"
        sx={{
          width: { md: "180px", xs: "100%" },
          mb: { xs: "10px", md: "0px" },
          fontSize: "17px",
          padding: "8px",
        }}
      >
        {t("addProduct")}
      </Button>
      <Button
        variant="text"
        sx={{ fontSize: "17px", width: { md: "180px", xs: "100%" } }}
        onClick={handleImportExcel}
      >
        {t("importExcel")}
      </Button>
      <Box
        className="d-flex"
        sx={{
          width: { xs: "100%", md: "auto" },
        }}
      >
        <CSVLink
          data={tblData}
          headers={csvHeader}
          filename={`${t("products")} (${new Date().toDateString()}).csv`}
          target="_blank"
          className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
        >
          {t("exportCSV")}
        </CSVLink>
      </Box>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: t("actions") },
          { id: "name", label: t("name") },
          { id: "barcode", label: t("barcode") },
          { id: "category", label: t("category") },
          { id: "subCat", label: t("subcategory") },
          { id: "unit", label: t("unitType") },
          { id: "tax", label: t("tax") },
          { id: "purchPrice", label: t("purchasePrice") },
          { id: "profitMargin", label: t("profitMargin") },
          { id: "sellPrice", label: t("sellPrice") },
          { id: "alertQty", label: t("alertQty") },
        ]}
        tblData={tblData}
      />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />

      {/* Generate Barcode */}
      <Dialog show={showBarcode} onClose={false} maxWidth="lg">
        <BarcodeGen value={barCode} setShowPopup={setShowBarcode} />
      </Dialog>

      {/* Upload File Form */}
      {showCsvUpl && (
        <Dialog show={showCsvUpl} onClose={false} maxWidth="md">
          <UplProdList
            {...{
              setShowForm: setShowCsvUpl,
              setAlert,
              alert,
              setProds,
            }}
          />
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  _list: state.pos.list,
  _type: state.pos.type,
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
  _clear: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(Products);
