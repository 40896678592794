import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";
import { connect } from "react-redux";
import * as actions from "../../api/actions/pos";
import { controller, endpoints } from "../../api/actions/api";
import { ToFormData } from "../../hooks/Method";
import moment from "moment";

const TopSellingProdTable = ({ _getByObj, sellsItem, inventories }) => {
  const { t } = useTranslation();
  const { org, selStore } = useContext(AppContext);
  const [values, setValues] = useState([]);

  useEffect(() => {
    selStore &&
      _getByObj(
        endpoints.Dashboard + controller.TopSellingProds,
        ToFormData({
          storeId: selStore,
          value: 20,
          date: moment().format("MM/YYYY"),
        }),
        null,
        (res) => {
          res.status === 200 && setValues([...res.result]);
        }
      );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  return (
    <Paper>
      {/* Chart */}
      <Typography varint="h6" className="fs-16 fw-5 gray-color p-15">
        {t("top20SellingProducts")}
      </Typography>
      <TableContainer sx={{ maxHeight: "310px" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "var(--primary-color)",
                  color: "var(--white-color)",
                  fontSize: "15px",
                  minWidth: "80px",
                  padding: "8px 8px",
                }}
              >
                {t("productName")}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "15px",
                  backgroundColor: "var(--primary-color)",
                  color: "var(--white-color)",
                  minWidth: "80px",
                  padding: "8px 8px",
                }}
              >
                {t("unitSell")}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "15px",
                  backgroundColor: "var(--primary-color)",
                  color: "var(--white-color)",
                  minWidth: "80px",
                  padding: "8px 8px",
                }}
              >
                {t("unitCost")}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "15px",
                  backgroundColor: "var(--primary-color)",
                  color: "var(--white-color)",
                  minWidth: "80px",
                  padding: "8px 8px",
                }}
              >
                {t("unitProfit")}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "15px",
                  backgroundColor: "var(--primary-color)",
                  color: "var(--white-color)",
                  minWidth: "80px",
                  padding: "8px 8px",
                }}
              >
                {t("totalOnStock")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ height: "200px" }}>
            {values.map((item, i) => (
              <TableRow hover key={i}>
                <TableCell
                  sx={{
                    fontSize: "14px",
                    padding: "6px 8px 6px 8px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {item[0].product.name}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "14px",
                    padding: "6px 8px 6px 8px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {item.map((j) => j.qty).reduce((x, y) => x + y, 0)}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "14px",
                    padding: "6px 8px 6px 8px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {org && org.curSymbol}{" "}
                  {item
                    .map((j) => Math.round(j.qty * j.price))
                    .reduce((x, y) => x + y, 0)
                    .toLocaleString()}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "14px",
                    padding: "6px 8px 6px 8px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {org && org.curSymbol}{" "}
                  {item
                    .map((j) =>
                      Math.round(
                        j.qty * (j.product.sellPrice - j.product.purchPrice)
                      )
                    )
                    .reduce((x, y) => x + y, 0)
                    .toLocaleString()}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "14px",
                    padding: "6px 16px 6px 16px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {item
                    .map((j) => j.inInv)
                    .reduce((x, y) => x + y, 0)
                    .toLocaleString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.pos.authUser,
  _defStore: state.pos.defStore,
});

const mapActiontoProps = { _getByObj: actions.getByObj };

export default connect(mapStateToProps, mapActiontoProps)(TopSellingProdTable);
