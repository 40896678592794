import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";

const PrintRecept = ({ data }) => {
  const { selStore, stores, org } = useContext(AppContext);
  const [store, setStore] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setStore({ ...stores.find((j) => j.id === selStore) });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  return (
    <div style={{ width: "88mm", fontFamily: "sans-serif" }}>
      <div
        style={{
          justifyContent: "center",
          display: "grid",
          alignItem: "center",
          textAlign: "center",
        }}
      >
        <span style={{ fontSize: "28px", fontWeight: "500" }}>
          {store.storeName}
        </span>
        <span
          style={{
            fontSize: "14px",
            maxWidth: "400px",
            marginTop: "10px",
            lineHeight: "18pt",
          }}
        >
          {store.address}
        </span>
        <span
          style={{
            fontSize: "14px",
            maxWidth: "400px",
            marginTop: "10px",
          }}
        >
          {store.phone}
        </span>
      </div>
      <div
        style={{
          marginTop: "25px",
        }}
      >
        <span style={{ display: "block", marginBottom: "10px" }}>
          {t("invoiceNo")} : {data.id}
        </span>
        <span style={{ display: "block" }}>
          {t("sellDate")}: {data.sellDate}
        </span>
      </div>
      <div style={{ borderBottom: "3px dotted #e0e0e0" }} />

      {/* Product List */}
      <div style={{ marginTop: "20px" }}>
        {/* Header */}
        <div
          style={{
            display: "flex",
            fontWeight: "500",
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          <span style={{ width: "60%" }}>{t("itemName")}</span>
          <span style={{ width: "20%", textAlign: "center" }}>{t("qty")}</span>
          <span style={{ width: "20%" }}>{t("price")}</span>
        </div>
        {/* Body */}
        {data.sellItems &&
          data.sellItems.map((item, i) => (
            <div
              key={i}
              style={{
                display: "flex",
                fontSize: "12px",
                padding: "5px 5px 5px 0px",
                borderBottom: "1px solid #e0e0e0",
              }}
            >
              <span style={{ width: "60%" }}>
                {item.itemType === 1 ? item.product.name : "Lottery"}
              </span>
              <span style={{ width: "20%", textAlign: "center" }}>
                {item.qty}
              </span>
              <span style={{ width: "20%" }}>
                {(org && org.curSymbol) + " " + item.price}
              </span>
            </div>
          ))}
      </div>
      <div style={{ borderBottom: "3px dotted var(--grayLight-color)" }} />
      <div style={{ display: "flex", fontWeight: "500", fontSize: "12px" }}>
        <div style={{ width: "40%", marginTop: "20px" }}>
          <span>
            {t("thanksForYourPurchase")} <br /> {t("seeYouAgain")}
          </span>
        </div>
        <div style={{ width: "60%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "3px 0px",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <span>{t("subtotal")}:</span>
            <span>{(org && org.curSymbol) + " " + data.subtotal}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "3px 0px",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <span>
              {t("tax")}({store.tax}%):
            </span>
            <span>(+) {(org && org.curSymbol) + " " + data.tax}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "3px 0px",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <span>{t("discount")}:</span>
            <span>(-) {(org && org.curSymbol) + " " + data.disAmt}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "3px 0px",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <span>{t("useCredit")}:</span>
            <span>{(org && org.curSymbol) + " " + data.creditAmt}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "3px 0px",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <span>{t("totalPay")}:</span>
            <span>{(org && org.curSymbol) + " " + data.totalPay}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "3px 0px",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <span>{t("paymentReceived")}:</span>
            <span>{(org && org.curSymbol) + " " + data.received}</span>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "3px 0px",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <span>{t("due")}:</span>
            <span>{(org && org.curSymbol) + " " + data.dueAmt}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "3px 0px",
            }}
          >
            <span> {data.changeAddedCredit ? t("credit") : t("change")}:</span>
            <span>{(org && org.curSymbol) + " " + data.change}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  store: state.pos.defStore,
});

const mapActionToProps = {};

export default connect(mapStateToProps, mapActionToProps)(PrintRecept);
