import React, { useContext, useState } from "react";
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../api/actions/pos";
import { controller, endpoints } from "../../api/actions/api";
import { RmvEmptyProp, ToFormNestedObj } from "../../hooks/Method";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";
import { InputQty } from "../../controls";

const Cart = (props) => {
  const {
    cartItems,
    setCartItems,
    subTotal,
    setSubTotal,
    totalPay,
    setTotalPay,
    tax,
    setTax,
    _post,
    _authUser,
  } = props;
  const navigate = useNavigate();
  const { selStore, stores, org } = useContext(AppContext);
  const { t } = useTranslation();
  const [store, setStore] = useState({});

  useEffect(() => {
    setStore({ ...stores.find((j) => j.id === selStore) });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  useEffect(() => {
    if (selStore) {
      var _total = cartItems
        .map((j) => j.qty * j.price)
        .reduce((x, y) => x + y, 0);
      setSubTotal(_total.toFixed(2));

      var _taxItemTotal = cartItems
        .filter((j) => j.tax === 1)
        .map((j) => j.qty * j.price)
        .reduce((x, y) => x + y, 0);
      var _tax = (parseFloat(store.tax) / 100) * _taxItemTotal;
      setTax(_tax.toFixed(2));

      setTotalPay((_tax + _total).toFixed(2));
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems, store]);

  const rmvCartItem = (item) => {
    setCartItems((x) => [...x.filter((j) => j.productId !== item.productId)]);

    // Post CancelCartItem
    if (item.productId) {
      const cancelItem = {
        cancelItem: RmvEmptyProp({
          ...item,
          productId: item.itemType === 2 ? "" : item.productId,
        }),
        userId: _authUser.id,
        storeId: selStore,
      };
      _post(
        endpoints.Sell + controller.PostCancelCart,
        ToFormNestedObj(cancelItem),
        null
      );
    }
  };

  const handleQtyChg = (e, productId) => {
    setCartItems((j) => [
      ...j.map((x) =>
        x.productId === productId ? { ...x, qty: e.target.value } : x
      ),
    ]);
  };

  const handleInc = (productId) => {
    setCartItems((x) => [
      ...x.map((j) =>
        j.productId === productId ? { ...j, qty: parseFloat(j.qty) + 1 } : j
      ),
    ]);
  };
  const handleDec = (productId) => {
    setCartItems((x) => [
      ...x.map((j) =>
        j.productId === productId
          ? { ...j, qty: parseFloat(j.qty) > 1 ? parseFloat(j.qty) - 1 : j.qty }
          : j
      ),
    ]);
  };

  return (
    <Paper className="shadow-xs br-8 p-15">
      {/* Details of products sold */}
      <TableContainer
        sx={{
          height: "calc(100vh - 296px)",
          overflow: "auto",
          paddingRight: "10px",
        }}
      >
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { padding: "0px" } }}
            >
              <TableCell></TableCell>
              <TableCell>{t("itemName")}</TableCell>
              <TableCell>{t("qty")}</TableCell>
              <TableCell align="right">{t("price")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cartItems.map((item, i) => (
              <TableRow sx={{ "td, th": { padding: "5px 0px" } }} key={i}>
                <TableCell>
                  <IconButton
                    onClick={() => rmvCartItem(item)}
                    size="small"
                    className="error-color p-2 mr-10"
                  >
                    <CloseRounded className="fs-18" />
                  </IconButton>
                </TableCell>
                <TableCell className="fs-16" sx={{ minWidth: "150px" }}>
                  <Typography variant="subtitle2" className="pr-10">
                    {item.name}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: "50px" }}>
                  <InputQty
                    value={item.qty}
                    onIncrease={() => handleInc(item.productId)}
                    onDecrease={() => handleDec(item.productId)}
                    onChange={(e) => handleQtyChg(e, item.productId)}
                  />
                </TableCell>
                <TableCell
                  align="right"
                  className="fs-16"
                  sx={{ minWidth: "140px" }}
                >
                  {org && org.curSymbol} {item.price}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box className="mt-20 gray-color">
        <Box className="d-flex j-between">
          <Typography variant="h6">{t("subtotal")}:</Typography>
          <Typography variant="h6">
            {org && org.curSymbol} {subTotal}
          </Typography>
        </Box>
        <Box className="d-flex j-between mb-10">
          <Typography variant="h6">
            {t("tax")}({store.tax}%):
          </Typography>
          <Typography variant="h6">
            {org && org.curSymbol} {tax}
          </Typography>
        </Box>

        <Button
          onClick={() => navigate("/pos/checkout")}
          className="btn-secondary d-flex j-between fs-20"
          sx={{ p: "12px" }}
          size="large"
          fullWidth
        >
          <span>{t("checkout")}</span>
          <span>
            {org && org.curSymbol} {totalPay}
          </span>
        </Button>
      </Box>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  _store: state.pos.defStore,
  _authUser: state.pos.authUser,
});

const mapActionToProps = {
  _post: actions.post,
};

export default connect(mapStateToProps, mapActionToProps)(Cart);
