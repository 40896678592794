import React, { useContext } from "react";
import NavItem from "../../layout/NavItem";
// Icon
import {
  InsightsTwoTone,
  PeopleRounded,
  LocalMallRounded,
  MoveDownRounded,
  ShoppingCartCheckoutRounded,
  ContactMailRounded,
  TroubleshootRounded,
  AdminPanelSettingsRounded,
  SettingsRounded,
  RequestQuoteRounded,
  PriceCheckRounded,
  InventoryRounded,
  LogoutRounded,
  GroupRounded,
} from "@mui/icons-material";
import { connect } from "react-redux";
import { AppContext } from "../../App";
import * as actions from "../../api/actions/pos";
import { useNavigate } from "react-router-dom";
import { controller, endpoints } from "../../api/actions/api";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";

const NevMenu = (props) => {
  const { _get, _authUser } = props;
  const { t } = useTranslation();
  const { setCLoader } = useContext(AppContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    setCLoader(true);
    const onComplete = (res) => {
      setCLoader(false);
      navigate("/");
    };
    _get(
      endpoints.Auth + controller.Logout,
      actions.ActionType.Auth,
      onComplete
    );
  };

  return (
    <>
      {_authUser.role === 1 && (
        <NavItem
          title={t("dashboard")}
          path="/dashboard"
          icon={<InsightsTwoTone />}
        />
      )}
      {_authUser.role !== 3 && (
        <>
          <NavItem
            title={t("products")}
            icon={<LocalMallRounded />}
            child={[
              { title: t("products"), path: "products" },
              { title: t("categories"), path: "categories" },
            ]}
          />

          <NavItem
            title={t("inventory")}
            path="/inventory"
            icon={<InventoryRounded />}
          />

          <NavItem
            title={t("purchases")}
            icon={<ShoppingCartCheckoutRounded />}
            child={[
              { title: t("purchases"), path: "purchases" },
              { title: t("purchasesReturn"), path: "purchases-return" },
            ]}
          />
          <NavItem
            title={t("suppliers")}
            path="suppliers"
            icon={<ContactMailRounded />}
          />
          <NavItem
            title={t("customers")}
            path="customers"
            icon={<GroupRounded />}
          />
          <NavItem
            title={t("expenses")}
            path="expenses"
            icon={<RequestQuoteRounded />}
          />
          <NavItem
            title={t("sells")}
            path="sells"
            icon={<PriceCheckRounded />}
          />
          <NavItem
            title={t("stockTransfer")}
            path="stock-transfer"
            icon={<MoveDownRounded />}
          />
        </>
      )}
      {_authUser.role === 1 && (
        <>
          <NavItem
            title={t("reports")}
            icon={<TroubleshootRounded />}
            child={[
              { title: t("storeReport"), path: "store-report" },
              { title: t("productReport"), path: "product-report" },
              { title: t("purchaseReport"), path: "purchase-report" },
              { title: t("registerReport"), path: "register-report" },
              { title: t("expenseReport"), path: "expense-report" },
              { title: t("productsSellVolume"), path: "products-sell-volume" },
              { title: t("employeeReport"), path: "employee-report" },
              { title: t("topSelling"), path: "top-selling" },
              { title: t("return"), path: "return" },
            ]}
          />
          <NavItem title={t("user")} path="/users" icon={<PeopleRounded />} />
          <NavItem
            title={t("HR")}
            icon={<AdminPanelSettingsRounded />}
            child={[
              { title: t("payBills"), path: "paybills" },
              { title: t("salaryPayment"), path: "salary-payment" },
              { title: t("salaryHistory"), path: "salary-history" },
              { title: t("employeeHistory"), path: "employee-history" },
            ]}
          />
          <NavItem
            title={t("master")}
            icon={<SettingsRounded />}
            child={[
              { title: t("POSItem"), path: "pos_items" },
              { title: t("store"), path: "stores" },
              { title: t("defaultStore"), path: "default_store" },
              { title: t("organization"), path: "setting_form" },
            ]}
          />
        </>
      )}
      {/* ==== Logout ==== */}
      <ListItemButton onClick={() => handleLogout()}>
        <ListItemIcon>
          <LogoutRounded />
        </ListItemIcon>
        <ListItemText primary={t("logout")} />
      </ListItemButton>
    </>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.pos.authUser,
});

const mapActiontoProps = {
  _get: actions.get,
};

export default connect(mapStateToProps, mapActiontoProps)(NevMenu);
