import { Avatar, Divider, Grid, InputAdornment } from "@mui/material";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Input,
  InputAuto,
  InputCheck,
  InputDate,
} from "../../controls";
import * as actions from "../../api/actions/pos";
import { DropLi, Model, UseForm } from "../../hooks";
import { controller, endpoints } from "../../api/actions/api";
import { NestedFormData, PrintDiv, RmvEmptyProp } from "../../hooks/Method";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";

const Checkout = (props) => {
  const {
    subTotal,
    cartItems,
    totalPay,
    tax,
    setReceptData,
    handleClearCart,
    _post,
    _authUser,
    setAlert,
    _getById,
  } = props;
  const { SellMdl } = Model();
  const { t } = useTranslation();
  const { PayType, SellType } = DropLi();
  const {
    values,
    errors,
    setErrors,
    handleInpChg,
    handleInpCheck,
    handleInpDate,
    setValues,
  } = UseForm(SellMdl);
  const [cstmrs, setCstmrs] = useState([]);
  const [selCstmr, setSelCstmr] = useState({});
  const [loading, setLoading] = useState(false);
  const { selStore, stores, org } = useContext(AppContext);
  const [store, setStore] = useState({});
  const [cstmrAccBal, setCstmrAccBal] = useState(0);

  useEffect(() => {
    selCstmr.id
      ? _getById(
          endpoints.Customer + controller.GetCstmrAccBal,
          selCstmr.id,
          null,
          (res) => setCstmrAccBal(res.result)
        )
      : setCstmrAccBal(0);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selCstmr]);

  useEffect(() => {
    // Get Stores
    setStore(stores.find((j) => j.id === selStore));

    // Get Customers
    const onComplete = (res) => {
      res &&
        res.status === 200 &&
        setCstmrs([
          ...res.result.map((j) => ({
            label: j.firstName + " " + j.lastName,
            subtitle: j.cstmrCode + " | " + j.phone,
            ...j,
          })),
        ]);
    };
    _getById(
      endpoints.Customer + controller.GetAllByStore,
      selStore,
      null,
      onComplete
    );

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  const handleSubmit = () => {
    if (values.sellType === 2 && !values.dueDate) {
      setAlert({
        isOpen: true,
        type: "warning",
        title: t("warning"),
        subTitle: t(""),
      });
      setErrors((j) => ({ ...j, dueDate: "dueDateIsRequired" }));
    } // **** Submit Action ****
    else if (
      values.sellItems.length > 0 &&
      values.received !== "" &&
      (values.sellType === 2 ||
        parseFloat(values.received) >=
          parseFloat(values.totalPay) - parseFloat(values.disAmt))
    ) {
      setLoading(true);
      const onComplete = (res) => {
        if (res.status === 200) {
          setReceptData(res.result);
          setTimeout(() => {
            PrintDiv("printSellRecept");
            setLoading(false);
            handleClearCart();
          }, 1500);
        } else {
          setLoading(false);
          setAlert({
            isOpen: true,
            type: "warning",
            title: t("warning"),
            subTitle: res.msg,
          });
        }
      };
      values.sellItems = values.sellItems.map((j) =>
        j.itemType === 2 ? { ...j, productId: "" } : j
      );
      _post(
        endpoints.Sell,
        NestedFormData(RmvEmptyProp(values)),
        null,
        onComplete
      );
    } else {
      if (values.sellItems.length <= 0) {
        setAlert({
          isOpen: true,
          type: "warning",
          title: t("warning"),
          subTitle: t("noItemInCart"),
        });
      } else if (!values.received) {
        setAlert({
          isOpen: true,
          type: "warning",
          title: t("warning"),
          subTitle: t("paymentFieldRequired"),
        });
      } else if (
        parseFloat(values.received) <
        values.totalPay - values.disAmt
      ) {
        setAlert({
          isOpen: true,
          type: "warning",
          title: t("warning"),
          subTitle: t("wrongAmount"),
        });
      }
    }
  };

  useEffect(() => {
    setValues((j) => ({
      ...j,
      storeId: selStore,
      sellItems: cartItems,
      taxPct: store.tax,
      tax: tax,
      totalPay: totalPay,
      received: totalPay,
      disAmt: 0,
      subtotal: subTotal,

      userId: _authUser.id,
      paymentType: 1,
    }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPay]);

  useEffect(() => {
    if (values.sellType === 1 && values.paymentType !== 3) {
      setValues((j) => ({
        ...j,
        change: (j.received - j.totalPay).toFixed(2),
      }));
    } else setValues((j) => ({ ...j, change: "" }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.sellType, values.received]);

  useEffect(() => {
    setValues((j) => ({ ...j, dueAmt: j.sellType === 1 ? "" : j.totalPay }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.sellType, values.totalPay]);

  const handleCstmr = (e) => {
    var cstmrVal = cstmrs.find((j) => j.id === e.target.value);
    cstmrVal ? setSelCstmr(cstmrVal) : setSelCstmr({});
    setValues((j) => ({
      ...j,
      cstmrId: e.target.value,
      sellType: !e.target.value ? 1 : j.sellType,
      received: (totalPay - j.disAmt).toFixed(2),
    }));
  };

  const handlePayType = (id) => {
    if (id === 3 && !values.cstmrId) {
      setAlert({
        isOpen: true,
        type: "warning",
        title: t("warning"),
        subTitle: t("pleaseFirstSelectCustomer"),
      });
    } else if (id === 3 && cstmrAccBal <= 0) {
      setAlert({
        isOpen: true,
        type: "warning",
        title: t("warning"),
        subTitle: t("cstmrNotHaveAccBal"),
      });
    } else {
      setValues((j) => {
        // check is that credit payment
        const amt =
          id === 3 ? parseFloat(values.totalPay) - cstmrAccBal : totalPay;
        const _amt = 0 > amt ? 0 : amt;

        // ******
        return {
          ...j,
          paymentType: id,
          received: _amt,
        };
      });
    }
  };

  const handleSellType = (id) => {
    setValues((j) => ({ ...j, sellType: id }));
    if (id === 2) {
      setValues((j) => ({
        ...j,
        dueAmt: j.received,
        received: "0.00",
        paymentType: "",
      }));
    } else {
      setValues((j) => ({
        ...j,
        dueAmt: "",
        received: totalPay,
        paymentType: 1,
      }));
    }
  };

  const handleDue = (e) => {
    handleInpChg(e);
    var due = parseFloat(e.target.value);
    due
      ? setValues((j) => ({
          ...j,
          received:
            totalPay -
            (due +
              parseFloat(j.disAmt) +
              (j.paymentType === 3 ? parseFloat(j.creditAmt) : 0)),
        }))
      : setValues((j) => ({ ...j, received: totalPay - parseFloat(j.disAmt) }));
  };

  const handleReceived = (e) => {
    handleInpChg(e);
    setValues((j) => ({
      ...j,
      dueAmt:
        j.sellType === 2
          ? (j.totalPay - parseFloat(e.target.value || 0)).toFixed(2)
          : "",
    }));
  };

  useEffect(() => {
    if (values.paymentType === 3) {
      var useCredit = parseFloat(values.creditAmt || 0);
      setValues((j) => {
        const _totalPay = values.disAmt
          ? (
              parseFloat(totalPay) -
              (parseFloat(values.disAmt) + useCredit)
            ).toFixed(2)
          : totalPay - useCredit;
        const finalAmt = _totalPay > 0 ? _totalPay : 0;
        return {
          ...j,
          totalPay: finalAmt,
          received: j.sellType === 2 ? 0 : finalAmt,
        };
      });
    } else {
      setValues((j) => {
        const _totalPay = values.disAmt
          ? (parseFloat(totalPay) - parseFloat(values.disAmt)).toFixed(2)
          : totalPay;
        return {
          ...j,
          creditAmt: 0,
          totalPay: _totalPay,
          received: j.sellType === 2 ? j.received : _totalPay,
        };
      });
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.creditAmt, values.disAmt, values.paymentType]);

  return (
    <>
      <Grid container spacing={2} className="pr-10">
        {/*====  Customer Section ====*/}
        <Grid item xs={12} md={8}>
          <div className="d-flex">
            <div className="w-100pr">
              <InputAuto
                className="fs-20"
                variant="outlined"
                placeholder={t("selectCustomer")}
                value={selCstmr.id}
                options={cstmrs}
                onChange={handleCstmr}
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: "22px",
                    fontWeight: "500",
                    color: "var(--gray-color)",
                  },
                }}
              />
            </div>
            {selCstmr.id && (
              <div className="d-flex g-10 ml-10">
                {SellType &&
                  SellType.map((type, ind) => (
                    <Button
                      key={ind}
                      variant="text"
                      size="small"
                      onClick={() => handleSellType(type.id)}
                      className={`h-100pr fs-16 ${
                        type.id === values.sellType && "bg-primary white-color"
                      }`}
                    >
                      {type.label}
                    </Button>
                  ))}
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            variant="text"
            onClick={handleSubmit}
            fullWidth
            loading={loading}
            className="bg-error white-color d-flex h-100pr fs-24"
          >
            {t("sale")}
          </Button>
        </Grid>

        {values.cstmrId && (
          <Grid item xs={12}>
            <div className="d-flex g-15 a-center j-between j- mb-5 mt-5">
              <div className="d-flex g-15">
                <Avatar sx={{ width: 50, height: 50 }} src={selCstmr.photo} />
                <div className="d-grid gray-color">
                  <span className="fs-20 fw-5">{selCstmr.label}</span>
                  <span className="fs-14">{selCstmr.subtitle}</span>
                </div>
              </div>

              <div className="d-grid gray-color">
                <span>
                  {org && org.curSymbol}&nbsp;
                  <span className="fs-26 fw-6">{cstmrAccBal.toFixed(2)}</span>
                </span>
                <span className="fs-12 tx-right">{t("storeCredit")}</span>
              </div>

              {/* Due Properties */}
              {values.sellType === 2 && (
                <div className="d-grid gray-color g-5">
                  <Input
                    fullWidth
                    placeholder={t("dueAmount")}
                    name="dueAmt"
                    value={values.dueAmt}
                    onChange={handleDue}
                    className="rmv-arrow br-8"
                    variant="outlined"
                    type="number"
                    size="small"
                    onClick={(e) => e.target && e.target.select()}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span className="gray-color ">
                            {org && org.curSymbol}
                          </span>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <InputDate
                    placeholder={t("dueDate")}
                    name="dueDate"
                    value={values.dueDate}
                    error={errors.dueDate}
                    variant="outlined"
                    size="small"
                    minDate={new Date()}
                    onChange={(date) =>
                      handleInpDate(
                        "dueDate",
                        new Date(date).toLocaleDateString()
                      )
                    }
                  />
                </div>
              )}
            </div>
          </Grid>
        )}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {/*==== Accounts Section ====*/}
        {values.paymentType === 3 && (
          <>
            <Grid item xs={12} md={6} className="d-flex a-center">
              <span className="fs-16 fw-5 tx-upp gray-color">
                {t("useCredit")}
              </span>
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                fullWidth
                placeholder={t("enterAmount")}
                name="creditAmt"
                value={values.creditAmt}
                onChange={(e) =>
                  e.target.value <= cstmrAccBal && handleInpChg(e)
                }
                className="bg-white rmv-arrow br-8"
                variant="outlined"
                type="number"
                size="small"
                onClick={(e) => e.target.select()}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span className="gray-color ">
                        {org && org.curSymbol}
                      </span>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={6}>
          <span className="fs-24 fw-5 gray-color tx-upp">
            {t("amountToPay")}
          </span>
        </Grid>
        <Grid item xs={12} md={6} className="gray-color ml-25 d-flex a-center">
          {parseFloat(values.disAmt) && values.totalPay ? (
            <div className="d-flex a-end g-10">
              <del className="fs-16 fw-5 gray-color ">
                {org && org.curSymbol} &nbsp;
                {parseFloat(values.totalPay) + parseFloat(values.disAmt)}
              </del>
              <div>
                <span className="fs-24 gray-color">
                  {org && org.curSymbol}&nbsp;
                </span>
                <span className="fs-26 fw-5 gray-color">{values.totalPay}</span>
              </div>
            </div>
          ) : (
            <div>
              <span className="fs-24 gray-color">{org && org.curSymbol}</span>
              &nbsp;
              <span className="fs-28 fw-5 gray-color">{values.totalPay}</span>
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={6} className="d-flex a-center">
          <span className="fs-16 fw-5 tx-upp gray-color">
            {t("additionalDiscount")}
          </span>
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            fullWidth
            placeholder={t("enterAmount")}
            name="disAmt"
            value={values.disAmt}
            onChange={(e) => {
              parseFloat(e.target.value || 0) <= totalPay && handleInpChg(e);
            }}
            className="bg-white rmv-arrow br-8"
            variant="outlined"
            type="number"
            size="small"
            onClick={(e) => e.target.select()}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <span className="gray-color ">{org && org.curSymbol}</span>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} md={6} className="d-flex a-center">
          <span className="fs-24 tx-upp fw-5 gray-color">
            {t("paymentReceived")}
          </span>
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            fullWidth
            placeholder={t("enterAmount")}
            name="received"
            value={values.received}
            onChange={handleReceived}
            className="bg-white rmv-arrow br-8"
            variant="outlined"
            type="number"
            onClick={(e) => e.target.select()}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <span className="fs-20"> {org && org.curSymbol}</span>
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiInputBase-input": {
                fontSize: "22px",
                fontWeight: "500",
                color: "var(--gray-color)",
              },
            }}
          />
          {values.sellType === 1 && values.change > 0 && (
            <div className="d-grid">
              <div className="d-flex g-10 a-center gray-color">
                <span className="fs-14">{t("change")} &nbsp;</span>
                <div>
                  <span>{org && org.curSymbol}&nbsp;</span>
                  <span className="fs-18 fw-5">{values.change}</span>
                </div>
              </div>
              {values.cstmrId && (
                <div>
                  <InputCheck
                    label={t("addChangeAsCreditBalance.")}
                    size="small"
                    className="gray-color"
                    onChange={handleInpCheck}
                    name="changeAddedCredit"
                    checked={values.changeAddedCredit}
                  />
                </div>
              )}
            </div>
          )}
        </Grid>

        <Grid item xs={12}>
          <Input
            fullWidth
            placeholder={t("keepNoteIfYouWant")}
            name="note"
            value={values.note}
            onChange={handleInpChg}
            className="bg-white rmv-arrow br-8"
            variant="outlined"
            multiline
            rows={3}
          />
        </Grid>
        {PayType &&
          PayType.map((type, ind) => (
            <Grid item xs={12} md={3} key={ind}>
              <Button
                variant="text"
                onClick={() => handlePayType(type.id)}
                sx={{
                  height: "100%",
                  width: "100%",
                  fontSize: "22px",
                  m: "15px 0px 15px 0px",
                }}
                className={
                  (type.id === values.paymentType &&
                    "bg-primary white-color") ||
                  ""
                }
              >
                {type.label}
              </Button>
            </Grid>
          ))}
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => ({
  _type: state.pos.type,
  _store: state.pos.defStore,
  _authUser: state.pos.authUser,
});

const mapActionToProps = {
  _post: actions.post,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(Checkout);
