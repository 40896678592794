import React, { useContext, useEffect, useState } from "react";
import { Dialog, Table } from "../../../layout";
import { Box, IconButton, Tooltip } from "@mui/material";
import { InputDate } from "../../../controls";
import { subDays } from "date-fns";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/pos";
import { controller, endpoints } from "../../../api/actions/api";
import { AppContext } from "../../../App";
import { PaymentsRounded, VisibilityRounded } from "@mui/icons-material";
import ViewProd from "../../sells/ViewProd";
import MakePayment from "./MakePayment";

const Receipt = ({ cstmr, _getById }) => {
  const { t } = useTranslation();
  const { org } = useContext(AppContext);
  const [sells, setSells] = useState([]);
  const [viewData, setViewData] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [paymentData, setPaymentData] = useState();
  const [showPayForm, setShowPayForm] = useState(false);
  const [sortDate, setSortDate] = useState({
    fromDate: `1/1/${new Date().getFullYear()}`,
    toDate: `12/1/${new Date().getFullYear()}`,
  });

  const handleDate = (name, newVal) => {
    setSortDate((x) => ({ ...x, [name]: newVal }));
  };

  useEffect(() => {
    cstmr &&
      _getById(
        endpoints.Sell + controller.GetSellsByCstmr,
        cstmr.id,
        null,
        (res) => {
          setSells([...res.result]);
        }
      );

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cstmr]);

  const handleView = (data) => {
    setViewData(data);
    setShowForm(!showForm);
  };

  const handleViewPay = (data) => {
    setPaymentData(data);
    setShowPayForm(true);
  };

  const HeaderComp = () => (
    <Box
      className="d-flex a-center w-100pr"
      sx={{ mb: { xs: "10px", md: "0px" } }}
    >
      <InputDate
        variant="outlined"
        fullWidth
        size="small"
        name="fromDate"
        value={sortDate.fromDate}
        views={["day", "month", "year"]}
        openTo="day"
        minDate={new Date("01/01/2000")}
        maxDate={subDays(new Date(sortDate.toDate), 1)}
        onChange={(date) =>
          handleDate("fromDate", new Date(date).toLocaleDateString())
        }
      />
      <span className="ml-10 mr-10 fs-16 dark-color">TO</span>
      <InputDate
        variant="outlined"
        fullWidth
        size="small"
        name="toDate"
        value={sortDate.toDate}
        minDate={moment(sortDate.fromDate, "MM/DD/YYYY").add(1, "days")}
        views={["day", "month", "year"]}
        openTo="day"
        onChange={(date) =>
          handleDate("toDate", new Date(date).toLocaleDateString())
        }
      />
    </Box>
  );

  const ActionComp = (props) => (
    <Box>
      <Tooltip arrow title={t("view")}>
        <IconButton
          onClick={() => handleView(props)}
          size="small"
          className="btn-primary mr-5"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      {props.sellType === "Due" && (
        <Tooltip arrow title={t("payment")}>
          <IconButton
            onClick={() => handleViewPay(props)}
            size="small"
            className="mr-5"
          >
            <PaymentsRounded className="fs-18 m-2 success-color" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: t("actions") },
          { id: "sellType", label: t("sellType") },
          { id: "date", label: t("date") },
          { id: "id", label: t("invoice") },
          { id: "totalItem", label: t("totalItem") },
          { id: "amount", label: t("amount") },
          { id: "username", label: t("cashier") },
        ]}
        tblData={[
          ...sells.map((j) => ({
            ...j,
            sellType: j.sellType === 1 ? "Paid" : "Due",
            date: (
              <div className="d-grid fs-14 fw-5">
                <span>Sell Date: {j.sellDate}</span>
                {j.sellType === 2 && (
                  <span
                    className={`${
                      new Date() >
                        moment(j.nextDueDate || j.dueDate, "MM/DD/YYYY") &&
                      "error-color"
                    }`}
                  >
                    Due Date: {j.nextDueDate || j.dueDate}
                  </span>
                )}
              </div>
            ),
            totalItem: j.sellItems.length,
            amount: (
              <div className="d-grid fs-14 fw-5">
                <span>
                  Inv. Amount: {org && org.curSymbol + " " + j.totalPay}
                </span>
                {j.dueAmt !== 0 && (
                  <span>Due: {org && org.curSymbol + " " + j.dueAmt}</span>
                )}
              </div>
            ),
            username: j.user.firstName + " " + j.user.lastName,
          })),
        ]}
      />

      {/* Edit Dialog */}
      {viewData.id && (
        <Dialog show={showForm} maxWidth="lg" onClose={false}>
          <ViewProd {...{ setShowForm, viewData }} />
        </Dialog>
      )}

      {showPayForm && (
        <Dialog show={showPayForm} onClose={false}>
          <MakePayment
            {...{ setShowForm: setShowPayForm, paymentData, setSells }}
          />
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(Receipt);
