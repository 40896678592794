import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Table } from "../../layout";
import * as actions from "../../api/actions/pos";
import { useContext } from "react";
import { AppContext } from "../../App";
import { controller, endpoints } from "../../api/actions/api";
import { useTranslation } from "react-i18next";
import { ChangeArrayKey } from "../../hooks/Method";
import { Box } from "@mui/material";
import { CSVLink } from "react-csv";

const ProductReport = (props) => {
  const { _getById } = props;
  const { setLoader, selStore, org } = useContext(AppContext);
  const { t } = useTranslation();
  const [sells, setSells] = useState([]);
  const [csvHeader, setCsvHeader] = useState([]);
  const [tblData, setTblData] = useState([]);

  const onComplete = (res) => {
    if (res.status === 200 && res.result.length > 0) {
      const filteredProds = [];

      const _prods = res.result
        .map((j) => j.sellItems.filter((x) => x.itemType === 1))
        .flat();

      _prods.forEach((j) => {
        var prod = _prods.filter((x) => x.product.id === j.product.id);
        // Check the product list is exist in the list before push to the list.
        !filteredProds.some(
          (x) => JSON.stringify(x) === JSON.stringify(prod)
        ) && filteredProds.push(prod);
      });
      setSells(filteredProds);
      setLoader(false);
    } else setLoader(false);
  };

  useEffect(() => {
    if (selStore) {
      setLoader(true);
      _getById(
        endpoints.Sell + controller.GetSellsByStore,
        selStore,
        null,
        onComplete
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  useEffect(() => {
    setCsvHeader([
      { key: "name", label: t("productName") },
      { key: "totalSold", label: t("totalSold") },
      { key: "purchPrice", label: t("purchasePrice") },
      { key: "sellPrice", label: t("sellPrice") },
      { key: "profit", label: t("profit") },
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTblData([
      ...sells.map((prods) => {
        return {
          ...prods,
          name: prods[0].product.name,
          totalSold: prods.map((j) => j.qty).reduce((x, y) => x + y, 0),
          purchPrice:
            (org && org.curSymbol) +
            " " +
            prods
              .map((j) => j.product.purchPrice * j.qty)
              .reduce((x, y) => x + y, 0)
              .toFixed(2),

          sellPrice:
            (org && org.curSymbol) +
            " " +
            prods
              .map((j) => j.product.sellPrice * j.qty)
              .reduce((x, y) => x + y, 0)
              .toFixed(2),

          profit:
            (org && org.curSymbol) +
            " " +
            (
              prods
                .map((j) => j.product.sellPrice * j.qty)
                .reduce((x, y) => x + y, 0) -
              prods
                .map((j) => j.product.purchPrice * j.qty)
                .reduce((x, y) => x + y, 0)
            ).toFixed(2),
          catName: prods[0].product.category && prods[0].product.category.name,
        };
      }),
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sells]);

  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      <Box
        className="d-flex"
        sx={{
          width: { xs: "100%", md: "auto" },
        }}
      >
        <CSVLink
          data={tblData}
          headers={csvHeader}
          filename={`${t("productReport")} (${new Date().toDateString()}).csv`}
          target="_blank"
          className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
        >
          {t("exportCSV")}
        </CSVLink>
      </Box>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={() => <div></div>}
        HeaderComp={HeaderComp}
        tblHead={[...ChangeArrayKey(csvHeader, "key", "id")]}
        tblData={tblData}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  _list: state.pos.list,
  _type: state.pos.type,
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(ProductReport);
