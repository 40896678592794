import React, { useContext, useEffect, useState } from "react";
import { Alert, Table } from "../../layout";
import { Avatar, Box, IconButton, Tooltip, Button } from "@mui/material";
import {
  ClearRounded,
  EditRounded,
  VisibilityRounded,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import { connect } from "react-redux";
import * as actions from "../../api/actions/pos";
import { controller, endpoints } from "../../api/actions/api";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";

const Customers = (props) => {
  const { _getById, _delete } = props;
  const navigate = useNavigate();
  const { setLoader, selStore, org } = useContext(AppContext);
  const { t } = useTranslation();
  const [tblData, setTblData] = useState([]);
  const [csvHeader, setCsvHeader] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setCsvHeader([
      { key: "cstmrCode", label: t("customerCode") },
      { key: "firstName", label: t("firstName") },
      { key: "lastName", label: t("lastName") },
      { key: "phone", label: t("phone") },
      { key: "email", label: t("email") },
      { key: "storeCredit", label: t("storeCredit") },
      { key: "hasCard", label: t("hasCard") },
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onComplete = (res) => {
      res && res.status === 200 && setTblData([...res.result]);
      setLoader(false);
    };

    setLoader(true);
    _getById(
      endpoints.Customer + controller.GetAllByStore,
      selStore,
      null,
      onComplete
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  const handleAddEdit = (id) => {
    navigate(`customer-form/${id || ""}`);
  };

  const handleView = (id) => {
    navigate(`/view-customer/${id}`);
  };

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: t("areYouSure"),
      subTitle: t("cstmrDltTitle"),
      onConfirm: () => {
        const onComplete = (res) => {
          if (res && res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: t("dltSuccessTitle"),
              subTitle: res.msg,
            });
            setTblData((x) => [...x.filter((j) => j.id !== res.result.id)]);
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: t("error"),
              subTitle: res.msg,
            });
          }
        };
        _delete(endpoints.Customer, id, null, onComplete);
      },
    });
  };

  const ActionComp = (val) => (
    <div className="bs-secondary br-8 w-fit">
      <Tooltip title="View">
        <IconButton
          onClick={() => handleView(val.id)}
          size="small"
          color="primary"
          className="mr-5 p-5"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Edit">
        <IconButton
          onClick={() => handleAddEdit(val.id)}
          size="small"
          color="primary"
          className="mr-5 p-5"
        >
          <EditRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          onClick={() => handleDlt(val.id)}
          size="small"
          color="error"
          className="mr-5 p-5"
        >
          <ClearRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </div>
  );
  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      <Button
        onClick={() => handleAddEdit()}
        className="btn-secondary"
        sx={{
          width: { md: "180px", xs: "100%" },
          mb: { xs: "10px", md: "0px" },
          fontSize: "17px",
          padding: "8px",
        }}
      >
        {t("addCustomer")}
      </Button>
      <Box
        className="d-flex"
        sx={{
          width: { xs: "100%", md: "auto" },
        }}
      >
        <CSVLink
          data={tblData.map((j) => ({
            ...j,
            hasCard: j.cardNumber ? t("yes") : t("no"),
          }))}
          headers={csvHeader}
          filename={`${t("customers")} (${new Date().toDateString()}).csv`}
          target="_blank"
          className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
        >
          {t("exportCSV")}
        </CSVLink>
      </Box>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "cstmr", label: t("customer") },
          { id: "contact", label: t("contact") },
          { id: "credit", label: t("storeCredit") },
          { id: "hasCard", label: t("hasCard") },
          { id: "actions", label: t("actions") },
        ]}
        tblData={tblData.map((j) => {
          return {
            ...j,
            cstmr: (
              <div className="d-flex g-15 a-center mb-5 mt-5">
                <Avatar className="bg-primary" src={j.photo} />
                <div className="d-grid">
                  <span className="">{j.firstName + " " + j.lastName}</span>
                  <span className="fs-12">{j.cstmrCode}</span>
                </div>
              </div>
            ),
            credit: (
              <span className="fs-18 fw-5">
                {org && org.curSymbol + " " + j.creditBal.toFixed(2)}
              </span>
            ),
            contact: (
              <div className="d-grid ">
                {j.email && (
                  <a
                    className="fs-14 primary-color c-pointer mb-3"
                    href={`mailto:${j.email}`}
                  >
                    ✉️ {j.email}
                  </a>
                )}
                <a
                  className="fs-14 primary-color c-pointer"
                  href={`tel:1955206146`}
                >
                  📞 {j.phone}
                </a>
              </div>
            ),
            hasCard: (
              <span className="fw-5">{j.cardNumber ? t("yes") : t("no")}</span>
            ),
          };
        })}
      />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(Customers);
