import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import { Button, InputFile } from "../../controls";
import { Alert, Form } from "../../layout";
import * as actions from "../../api/actions/pos";
import { controller, endpoints } from "../../api/actions/api";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { prod_excel_sheet_example } from "../../assets";
import { ToFormData } from "../../hooks/Method";
import { AppContext } from "../../App";

const UplProdList = (props) => {
  const { setShowForm, _post, setProds, alert, setAlert } = props;
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState("");
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const { selStore } = useContext(AppContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (file) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 200) {
          res.result &&
            res.result.length > 0 &&
            setProds((x) => [...res.result, ...x]);

          setAlert({
            isOpen: true,
            type: "success",
            title: t("success"),
            subTitle: res.msg,
          });
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: t("error"),
            subTitle: res.msg,
          });
        setShowForm(false);
      };
      _post(
        endpoints.Product + controller.ImportExcel,
        ToFormData({ file: file, storeId: selStore }),
        null,
        onComplete
      );
    } else setError("File is required");
  };

  const handleFile = (val) => {
    setFile(val.value);
  };

  const handleClose = () => {
    setShowForm(false);
  };

  return (
    <>
      <Box
        className="p-15"
        sx={{ maxWidth: { xs: "280px", md: "450px" }, minWidth: "380px" }}
      >
        <Form onSubmit={handleSubmit} noValidate>
          <div className="tx-center mb-30">
            <h2 className="h fw-5 mb-5 fs-20 tx-upp">Import Excel File</h2>
            <div className="pos-relative d-flex j-center">
              <div className="border-bottom w-40" />
            </div>
          </div>
          <div className="tx-center mb-30">
            <Typography className="fs-14">
              <span className="gray-color fs-16">
                * Please check the{" "}
                <a
                  className="fs-16 fw-5 primary-color tx-upp tx-und"
                  href={prod_excel_sheet_example}
                  rel="noreferrer"
                  target={"_blank"}
                >
                  Example
                </a>{" "}
                before upload.{" "}
              </span>
            </Typography>
          </div>
          <div className="mb-15">
            <InputFile
              label="Upload"
              name="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              src={file}
              values={file}
              onChange={handleFile}
              error={error}
              rmv={() => {
                setFile("");
              }}
            />
          </div>

          <Box className="d-flex">
            <Button
              onClick={handleClose}
              fullWidth
              className="mr-10"
              variant="text"
            >
              Close
            </Button>
            <Button fullWidth type="submit" loading={loading}>
              Save
            </Button>
          </Box>
        </Form>
      </Box>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.pos.authUser,
});

const mapActionToProps = {
  _post: actions.post,
};

export default connect(mapStateToProps, mapActionToProps)(UplProdList);
