import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { Box, Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import moment from "moment/moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as actions from "../../api/actions/pos";
import { AppContext } from "../../App";
import { controller, endpoints } from "../../api/actions/api";
import { ToFormData } from "../../hooks/Method";

const StoreExpenses = ({ _getByObj }) => {
  const { t } = useTranslation();
  const { selStore } = useContext(AppContext);
  const [values, setValues] = useState([]);

  useEffect(() => {
    selStore &&
      _getByObj(
        endpoints.Dashboard + controller.StoreExpenses,
        ToFormData({
          storeId: selStore,
          value: moment().format("MM/YYYY"),
        }),
        null,
        (res) => {
          res.status === 200 && setValues([...res.result]);
        }
      );

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  const options = {
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    labels: values.map((j) => j[0].expenseCat.name),
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "bottom",
      fontWeight: "600",
      fontSize: "15px",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
        },
      },
    ],
  };

  const series = values.map((j) =>
    j.map((x) => Math.round(x.amount)).reduce((x, y) => x + y, 0)
  );

  return (
    <Paper className="p-15">
      {/* Chart */}
      <Typography varint="h6" className="fs-18 fw-5 gray-color mb-20">
        {t("storeExpenses")}
      </Typography>
      <Box>
        <ReactApexChart
          options={options}
          series={series}
          type="donut"
          height={330}
        />
      </Box>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.pos.authUser,
  _defStore: state.pos.defStore,
});

const mapActiontoProps = { _getByObj: actions.getByObj };

export default connect(mapStateToProps, mapActiontoProps)(StoreExpenses);
