import React from "react";
import { Grid, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";

const Details = (props) => {
  const { cstmr, gender, accBalType } = props;
  const { t } = useTranslation();

  return (
    <Paper className="pt-5 bg-white shadow-xs">
      {cstmr ? (
        <Grid container spacing={3} className="p-15 dark-color mb-10">
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">
              {t("customerId")}:
            </span>
            <span className="fs-16 fw-4">{cstmr.id}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">
              {t("customerCode")}:
            </span>
            <span className="fs-16 fw-4">{cstmr.cstmrCode}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">
              {t("firstName")}:
            </span>
            <span className="fs-16 fw-4">{cstmr.firstName}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">
              {t("lastName")}:
            </span>
            <span className="fs-16 fw-4"> {cstmr.lastName}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">{t("gender")}:</span>
            <span className="fs-16 fw-4"> {gender}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">{t("phone")}:</span>
            <a
              className="link fs-16 fw-4 gray-color"
              href={`tel:${cstmr.phone}`}
            >
              {cstmr.phone}
            </a>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">{t("email")}:</span>
            <a
              className="link fs-16 fw-4 gray-color"
              href={`mailto:${cstmr.email}`}
            >
              {cstmr.email}
            </a>
          </Grid>

          <Grid item xs={12}>
            <div className="d-flex a-center mt-20">
              <span
                className="gray-color tx-upp fw-5 fs-14"
                style={{ minWidth: "70px" }}
              >
                {t("address")}:
              </span>
              <div className="bs-secondary w-100pr" />
            </div>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">
              {t("address1")}:
            </span>
            <span className="fs-16 fw-4">{cstmr.address1}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">
              {t("address2")}:
            </span>
            <span className="fs-16 fw-4">{cstmr.address2}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">{t("city")}:</span>
            <span className="fs-16 fw-4">{cstmr.city}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">{t("state")}:</span>
            <span className="fs-16 fw-4">{cstmr.state}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">{t("ZIP")}:</span>
            <span className="fs-16 fw-4">{cstmr.zip}</span>
          </Grid>
          <Grid item xs={12}>
            <div className="d-flex a-center mt-20">
              <span
                className="gray-color tx-upp fw-5 fs-14"
                style={{ minWidth: "150px" }}
              >
                {t("additionalDetails")}
              </span>
              <div className="bs-secondary w-100pr" />
            </div>
          </Grid>

          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">
              {t("companyName")}
            </span>
            <span className="fs-16 fw-4">{cstmr.company}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">
              {t("dateOfBirth")}
            </span>
            <span className="fs-16 fw-4">{cstmr.dob}</span>
          </Grid>
          <Grid item xs={12} md={4}>
            <span className="tx-xs tx-upp gray-color">{t("website")}:</span>
            <div className="flex-wrap mt-5">
              <a
                className="link fs-16 fw-4 gray-color word-break"
                href={cstmr.website}
                target="_blank"
                rel="noreferrer"
              >
                {cstmr.website}
              </a>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <span className="tx-xs tx-upp gray-color">{t("twitter")}:</span>
            <div className="flex-wrap mt-5">
              <a
                className="link fs-16 fw-4 gray-color"
                href={cstmr.twitter}
                target="_blank"
                rel="noreferrer"
              >
                {cstmr.twitter}
              </a>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="d-flex a-center mt-20">
              <span
                className="gray-color tx-upp fw-5 fs-14"
                style={{ minWidth: "80px" }}
              >
                {t("settings")}
              </span>
              <div className="bs-secondary w-100pr" />
            </div>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">
              {t("allowAnOnAccountBalance")}:
            </span>
            <span className="fs-16 fw-4">{accBalType}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 gray-color">
              {t("balanceLimit")}:
            </span>
            <span className="fs-16 fw-4">${cstmr.balLimit}</span>
          </Grid>
        </Grid>
      ) : (
        "Loading..."
      )}
    </Paper>
  );
};

export default Details;
