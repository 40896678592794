import { Grid } from "@mui/material";
import { Tabs } from "../../../layout";
import React, { useEffect, useState } from "react";
import CustomerInfo from "./CustomerInfo";
import Details from "./Details";
import Accounts from "./Accounts";
import { useParams } from "react-router-dom";
import { DropLi } from "../../../hooks";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/pos";
import { controller, endpoints } from "../../../api/actions/api";
import { useTranslation } from "react-i18next";
import Receipt from "./Receipt";

const ViewCstmr = (props) => {
  const { _getById } = props;
  const { id } = useParams();
  const [cstmr, setCstmr] = useState();
  const { t } = useTranslation();
  const { Gender, AccBalTypes } = DropLi();
  const [gender, setGender] = useState("");
  const [accBalType, setAccBalType] = useState("");

  useEffect(() => {
    id &&
      _getById(
        endpoints.Customer + controller.GetById,
        id,
        null,
        (res) => res.status === 200 && setCstmr({ ...res.result })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (cstmr) {
      // get name of the gender
      var _gender = Gender.find((j) => j.id === cstmr.gender);
      setGender(_gender ? _gender.label : "");

      // get name of the account balance type
      var _balType = AccBalTypes.find((j) => j.id === cstmr.accBalType);
      setAccBalType(_balType ? _balType.label : "");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cstmr]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8.5}>
          <div>
            <Tabs
              header={[
                { label: t("details") },
                { label: t("accounts") },
                { label: t("receipt") },
              ]}
            >
              <Details {...{ cstmr, gender, accBalType }} />
              <Accounts {...{ cstmr, accBalType }} />
              <Receipt {...{ cstmr, accBalType }} />
            </Tabs>
          </div>
        </Grid>
        <Grid item xs={12} md={3.5}>
          <CustomerInfo {...{ cstmr, gender, accBalType }} />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(ViewCstmr);
