import React, { useState } from "react";
import {
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { InputDate } from "../../controls";
import { Box } from "@mui/system";
import { AttachMoneyRounded } from "@mui/icons-material";
import { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../api/actions/pos";
import { controller, endpoints } from "../../api/actions/api";
import moment from "moment/moment";
import { useContext } from "react";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";

const StoreReports = (props) => {
  const { _post, _getById } = props;
  const { t } = useTranslation();
  const { setLoader, selStore, org } = useContext(AppContext);
  const [searchVal, setSearchVal] = useState(new Date());
  const [sells, setSells] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [voidSells, setVoidSells] = useState([]);
  const [cancelCarts, setCancelCarts] = useState([]);
  const [inventories, setInventories] = useState([]);
  const [grossProfit, setGrossProfit] = useState(0);
  const [totalExps, setTotalExps] = useState(0);
  const [totalSell, setTotalSell] = useState(0);
  const [netProfit, setNetProfit] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [voidAmount, setVoidAmount] = useState(0);
  const [refund, setRefund] = useState(0);
  const [openingInvVal, setOpeningInvVal] = useState(0);
  const [newInvVal, setNewInvVal] = useState(0);
  const [soldInvVal, setSoldInvVal] = useState(0);
  const [totalPurch, setTotalPurch] = useState(0);
  const [currentInvVal, setCurrentInvVal] = useState(0);

  const handleSearch = (date) => {
    setSearchVal(date);
  };

  const onComplete = (res) => {
    setLoader(false);
    if (res.status === 200) {
      setSells(res.result.sells);
      setExpenses(res.result.expense);
      setVoidSells(res.result.voidSells);
      setCancelCarts(res.result.cancelCarts);
      setInventories(res.result.inventories);
    }
  };

  useEffect(() => {
    selStore &&
      _getById(
        endpoints.Inventory + controller.GetProdsByStore,
        selStore,
        null,
        (res) => {
          if (res.status === 200) {
            setCurrentInvVal(
              res.result
                .map((j) => j.product.sellPrice * j.inInventory)
                .reduce((x, y) => x + y, 0)
                .toLocaleString()
            );
          }
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  useEffect(() => {
    if (searchVal && selStore) {
      setLoader(true);
      _post(
        endpoints.Store + controller.StoreReport,
        { storeId: selStore, value: moment(searchVal).format("MM/yyyy") },
        null,
        onComplete
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVal, selStore]);

  useEffect(() => {
    var _totalSells = sells.map((j) => j.subtotal).reduce((x, y) => x + y, 0);
    setTotalSell(_totalSells.toLocaleString());

    var _totalSoldInv = sells
      .map((j) => j.sellItems)
      .flat()
      .map((j) => j.itemType === 1 && j.product.sellPrice)
      .reduce((x, y) => x + y, 0);
    setSoldInvVal(_totalSoldInv.toLocaleString());

    var _totalPurchase = sells
      .map((j) => j.sellItems)
      .flat()
      .map((j) => j.itemType === 1 && j.product.purchPrice)
      .reduce((x, y) => x + y, 0);
    setTotalPurch(_totalPurchase.toLocaleString());

    var _grossProfit = _totalSells - _totalPurchase;
    setGrossProfit(_grossProfit.toLocaleString());

    var _totalExps = expenses.map((j) => j.amount).reduce((x, y) => x + y, 0);
    setTotalExps(_totalExps.toLocaleString());

    setNetProfit(
      (_grossProfit - (_totalPurchase + _totalExps)).toLocaleString()
    );

    var _totalItem = sells
      .map((j) => j.sellItems)
      .flat()
      .map((j) => j.qty)
      .reduce((x, y) => x + y, 0);
    setTotalItem(_totalItem);

    var _refunds = voidSells
      .filter((j) => j.cancelType === 2)
      .map((j) => j.subtotal)
      .reduce((x, y) => x + y, 0);
    setRefund(_refunds.toLocaleString());

    var _voidAmount = voidSells
      .filter((j) => j.cancelType === 1)
      .map((j) => j.subtotal)
      .reduce((x, y) => x + y, 0);
    setVoidAmount(_voidAmount.toLocaleString());

    var _openingInvVal = inventories
      .map((j) => j.product.sellPrice * j.inInventory)
      .reduce((x, y) => x + y, 0);
    setOpeningInvVal(_openingInvVal.toLocaleString());

    var _newInvVal = inventories
      .map(
        (j) =>
          j.product.sellPrice *
          j.invUsers.map((x) => x.addedInv).reduce((x, y) => x + y)
      )
      .reduce((x, y) => x + y, 0);
    setNewInvVal(_newInvVal.toLocaleString());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sells, expenses, voidSells, cancelCarts, inventories]);

  return (
    <>
      <Grid container spacing={3}>
        {/* Search  */}
        <Grid item xs={12}>
          <InputDate
            label={t("searchByMonth")}
            value={searchVal}
            views={["year", "month"]}
            openTo={"month"}
            variant="filled"
            onChange={(date) => handleSearch(new Date(date))}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className="p-20 br-8 shadow-xs">
            <Box className="d-flex j-between a-center">
              <Box>
                <Typography variant="h5" className="gray-color fw-5">
                  {t("grossProfit")}
                </Typography>
                <Typography variant="subtitle2" className="gray-color">
                  ({t("totalSellPrice")} - {t("totalPurchasePrice")})
                </Typography>
              </Box>
              <IconButton className="btn-primary">
                <AttachMoneyRounded />
              </IconButton>
            </Box>
            <Box className="mt-20 mb-5">
              <Typography variant="h4" className="deepDark-color fw-5">
                {org && org.curSymbol} {grossProfit}
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className="p-20 br-8 shadow-xs">
            <Box className="d-flex j-between a-center">
              <Box>
                <Typography variant="h5" className="gray-color fw-5">
                  {t("netProfit")}
                </Typography>
                <Typography variant="subtitle2" className="gray-color">
                  ({t("grossSell")} - ({t("totalPurchese")} +{" "}
                  {t("totalExpences")}))
                </Typography>
              </Box>
              <IconButton className="btn-primary">
                <AttachMoneyRounded />
              </IconButton>
            </Box>
            <Box className="mt-20 mb-5">
              <Typography variant="h4" className="deepDark-color fw-5">
                {org && org.curSymbol} {netProfit}
              </Typography>
            </Box>
          </Paper>
        </Grid>
        {/* Inventory */}
        <Grid item xs={12} md={6}>
          <Paper className="br-8 shadow-xs pb-10 pt-10">
            <Box className="tx-center mb-20">
              <Typography variant="subtitle1" className="fs-20 fw-5 gray-color">
                {t("inventory")}
              </Typography>
              <div className="bs-bottom mx-w-60 m-auto" />
            </Box>
            <Divider />
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t("openingInventoryValue")}:</TableCell>
                  <TableCell>
                    {org && org.curSymbol} {openingInvVal}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("newInventoryAdded")}:</TableCell>
                  <TableCell>
                    {org && org.curSymbol} {newInvVal}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("inventorySold")}:</TableCell>
                  <TableCell>
                    {org && org.curSymbol} {soldInvVal}
                  </TableCell>
                </TableRow>
                <TableRow sx={{ bgcolor: "var(--primaryTXS-color)" }}>
                  <TableCell>{t("currentInventoryValue")}:</TableCell>
                  <TableCell>
                    {org && org.curSymbol} {currentInvVal}
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Paper>
        </Grid>

        {/* Store details */}
        <Grid item xs={12} md={6}>
          <Paper className="br-8 shadow-xs pb-10 pt-10">
            <Box className="tx-center mb-20">
              <Typography variant="subtitle1" className="fs-20 fw-5 gray-color">
                {t("storeDetails")}
              </Typography>
              <div className="bs-bottom mx-w-60 m-auto" />
            </Box>
            <Divider />
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t("totalItemSold")}:</TableCell>
                  <TableCell>{totalItem}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("totalVoidSale")}:</TableCell>
                  <TableCell>
                    {org && org.curSymbol} {voidAmount}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("totalRefund")}:</TableCell>
                  <TableCell>
                    {org && org.curSymbol} {refund}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("totalItemCancelledFromCart")}:</TableCell>
                  <TableCell>{cancelCarts.length}</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Paper>
        </Grid>

        {/* Items */}
        <Grid item xs={12} md={6}>
          <Paper className="br-8 shadow-xs pb-10 pt-10">
            <Box className="tx-center mb-20">
              <Typography variant="subtitle1" className="fs-20 fw-5 gray-color">
                {t("items")}
              </Typography>
              <div className="bs-bottom mx-w-60 m-auto" />
            </Box>
            <Divider />
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t("totalSale")}:</TableCell>
                  <TableCell>
                    {org && org.curSymbol} {totalSell}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("totalPurchase")}:</TableCell>
                  <TableCell>
                    {org && org.curSymbol} {totalPurch}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("totalItemSold")}:</TableCell>
                  <TableCell>{totalItem}</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Paper>
        </Grid>

        {/* Expenses */}
        <Grid item xs={12} md={6}>
          <Paper className="br-8 shadow-xs pb-10 pt-10">
            <Box className="tx-center mb-20">
              <Typography variant="subtitle1" className="fs-20 fw-5 gray-color">
                {t("expenses")}
              </Typography>
              <div className="bs-bottom mx-w-60 m-auto" />
            </Box>
            <Divider />
            <Table size="small">
              <TableHead>
                {expenses.map((exp, i) => (
                  <TableRow key={i}>
                    <TableCell>{exp.expenseCat.name}:</TableCell>
                    <TableCell>
                      {org && org.curSymbol} {exp.amount}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow sx={{ bgcolor: "var(--primaryTXS-color)" }}>
                  <TableCell>{t("totalExpenses")}:</TableCell>
                  <TableCell> {totalExps}</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  _list: state.pos.list,
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _post: actions.post,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(StoreReports);
